import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { DeviceSizeService } from 'src/app/shared/services/device-size.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RealTimeCommunicationService } from 'src/app/shared/services/real-time-communication.service';
import { UsersServiceService } from 'src/app/shared/services/users-service.service';
import { WorkersAvatarsService } from 'src/app/shared/services/workers-avatars.service';
import { AddTaskDialogComponent } from '../add-task-dialog/add-task-dialog.component';
import { worker } from 'cluster';
import { MatTooltip } from '@angular/material/tooltip';
import { ChatService } from 'src/app/shared/services/chat.service';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.css'],
})
export class TaskViewComponent implements OnInit {
  @ViewChildren('comments') comments: QueryList<ElementRef>;

  @ViewChild('text') text;
  @ViewChild('input') input: ElementRef;
  task = null;
  commentToAdd;
  authorMode = false;
  savedComment = '';
  selectedWorker;
  previousLength = 0;
  commentToCheckId = null;
  messagesForWorkersSub: any;
  workers = [];
  removed: boolean;
  @ViewChild(MatTooltip) tooltip: MatTooltip;
  sub: any;

  constructor(
    public dialogRef: MatDialogRef<TaskViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    @Inject(LOCALE_ID) public locale: string,
    public deviceSizeService: DeviceSizeService,
    private usersService: UsersServiceService,
    private dialog: MatDialog,
    public workersAvatarsService: WorkersAvatarsService,
    private realTimeCommunicationService: RealTimeCommunicationService,
    private chatService: ChatService
  ) {}
  onTouchStart(tooltip: MatTooltip) {
    tooltip.show();
  }
  correctText() {
    if (this.commentToAdd.length > 10) {
      this.chatService.correctPolishText(this.commentToAdd);
    } else {
      alert('Za krótki tekst');
    }
  }
  ngOnInit(): void {
    this.sub = this.chatService
      .chatMessagesListener()
      .subscribe((messages: any) => {
        console.log(
          '🚀 ~ file: add-task-dialog.component.ts:41 ~ AddTaskDialogComponent ~ this.sub=this.chatService.chatMessagesListener ~ messages:',
          messages
        );

        this.commentToAdd = messages.res.choices[0].message.content;
      });
    this.workers = this.workersAvatarsService.workersWithAvatars.filter(
      (worker) =>
        worker.id &&
        worker.name !== this.data.task.author.name &&
        worker?.priority > 0
    );
    this.workers = this.helpers.sortArrayByProperty(
      this.workers,
      'priority',
      'desc'
    );
    console.log(
      '🚀 ~ file: task-view.component.ts ~ line 56 ~ TaskViewComponent ~ ngOnInit ~ this.teachers',
      this.workers
    );
    this.messagesForWorkersSub = this.realTimeCommunicationService
      .messagesForWorkersListener()
      .subscribe((message: any) => {
        this.handleUpdatedTaskMessage(message);
      });
    this.selectedWorker = this.data.selectedWorker;
    this.task = this.data.task;
    if (!this.task?.seenBy) {
      this.task.seenBy = [this.userDetailsService.getSimpleUserDetails().id];
    } else {
      if (
        this.task.seenBy.indexOf(
          this.userDetailsService.getSimpleUserDetails().id === -1
        )
      ) {
        this.task.seenBy.push(
          this.userDetailsService.getSimpleUserDetails().id
        );
      }
    }

    this.task.seenBy = this.helpers.removeDuplicatesSingle(this.task.seenBy);

    this.usersService.updateTasksData(
      this.selectedWorker.id,
      this.task.id,
      this.task
    );
    let unseenCommentFound = false;
    this.task.comments.forEach((comment) => {
      if (
        !comment.seenBy.includes(
          this.userDetailsService.getSimpleUserDetails().id
        )
      ) {
        unseenCommentFound = true;
        comment.seenBy.push(this.userDetailsService.getSimpleUserDetails().id);
      }
    });
    if (unseenCommentFound) {
      this.usersService.updateTasksCommentsVisibility(
        this.task.recipient.id,
        this.task
      );
    }
    this.handleIcons();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.comments && this.comments.last) {
        this.comments.last.nativeElement.focus();
      }
    }, 500);

    this.comments.changes.subscribe((data) => {
      console.log(
        '🚀 ~ file: task-view.component.ts:184 ~ TaskViewComponent ~ this.comments.changes.subscribe ~ this.comments',
        this.comments
      );
      console.log(
        '🚀 ~ file: task-view.component.ts:182 ~ TaskViewComponent ~ this.comments.changes.subscribe ~ data',
        data
      );
      if (this.comments && this.comments.last) {
        this.comments.last.nativeElement.focus();
        if (this.previousLength !== 0) {
          // this.input.nativeElement.focus()
        }
        this.previousLength = data.length;
      }
    });
    console.log(
      '🚀 ~ file: task-view.component.ts ~ line 98 ~ TaskViewComponent ~ this.comments.changes.subscribe ~ this.comments',
      this.comments
    );
  }
  addToConversation(task, worker) {
    const newComment = {
      seenBy: [this.userDetailsService.getSimpleUserDetails().id],
      text: worker.name + ' ' + 'dołączył(a) do rozmowy',
      date: Date.now(),
      author: {
        id: worker.id,
        name: worker.name,
        avatar: worker.avatar,
      },
    };
    let recipients = [];
    recipients = task.comments.map((comment) => {
      return comment.author.id;
    });
    recipients.push(worker.id);
    recipients = this.helpers.removeDuplicatesSingle(recipients);
    task.comments.push(newComment);
    this.usersService.addCommentToTask(
      this.selectedWorker.id,
      task.id,
      newComment,
      recipients
    );
  }
  addComment(task, readMode?) {
    let recipients = [];
    recipients = task.comments.map((comment) => {
      return comment.author.id;
    });
    recipients.push(this.selectedWorker.id);
    recipients.push(task.author.id);
    recipients = this.helpers.removeDuplicatesSingle(recipients);
    const newComment = {
      seenBy: [this.userDetailsService.getSimpleUserDetails().id],
      text: !readMode ? this.commentToAdd : 'Przeczytano',
      date: Date.now(),
      completion: task.completion,
      author: this.userDetailsService.getSimpleUserDetails(),
    };
    task.comments.push(newComment);
    recipients = recipients.filter(
      (recipient) =>
        recipient !== this.userDetailsService.getSimpleUserDetails().id
    );
    this.usersService.addCommentToTask(
      this.selectedWorker.id,
      task.id,
      newComment,
      recipients
    );
    this.commentToAdd = '';
  }
  handleUpdatedTaskMessage(message) {
    if (message.updatedTask) {
      let unseenCommentFound = false;
      if (message.updatedTask.id === this.task.id) {
        this.savedComment = this.helpers.clone(this.commentToAdd);
        this.task = message.updatedTask;

        const userDetails = this.userDetailsService.getSimpleUserDetails();
        if (userDetails && userDetails.id) {
          this.task.comments.forEach((comment) => {
            if (!comment.seenBy.includes(userDetails.id)) {
              unseenCommentFound = true;
              comment.seenBy.push(userDetails.id);
            }
          });
        }
        if (unseenCommentFound) {
          this.usersService.updateTasksCommentsVisibility(
            this.task.recipient.id,
            this.task
          );
        }
        this.handleIcons();
        this.commentToAdd = this.savedComment;
        this.savedComment = '';
      }
    }
  }

  handleIcons() {
    const involvedUsers = this.task.seenBy;
    this.task.comments.forEach((comment) => {
      comment.lastSeenBy = [];
    });
    involvedUsers.forEach((user) => {
      let commentsThatUserSeen = this.task.comments.filter((comment) => {
        return comment.seenBy.includes(user);
      });
      commentsThatUserSeen = commentsThatUserSeen.sort((a, b) => {
        return a.date - b.date;
      });
      if (commentsThatUserSeen?.length > 0) {
        commentsThatUserSeen[commentsThatUserSeen.length - 1].lastSeenBy.push(
          user
        );
      }
    });
  }
  taskCompletionChanged(task) {
    this.usersService.updateTasksCompletion(
      this.selectedWorker.id,
      task.id,
      task.completion
    );
  }
  editTask(task) {
    const dialogRef = this.dialog.open(AddTaskDialogComponent, {
      data: { editMode: true, task: task },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(
        '🚀 ~ file: task-view.component.ts ~ line 61 ~ TaskViewComponent ~ dialogRef.afterClosed ~ result',
        result
      );
      this.usersService.updateTasksData(this.selectedWorker.id, task.id, task);
    });
  }
  removeComment(comment, task) {
    task.comments = this.helpers.removeElementFromArray(task.comments, comment);
    // task.completion = null;

    this.usersService.removeCommentFromTask(
      this.selectedWorker.id,
      task.id,
      comment.id
    );
  }
  removeTask(task) {
    this.authorMode = true;
    this.removed = true;
    this.usersService.removeWorkersTask(this.selectedWorker.id, task.id);
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (!this.removed) {
      this.usersService.updateTasksCommentsVisibility(
        this.task.recipient.id,
        this.task
      );
    }
    this.messagesForWorkersSub.unsubscribe();
    this.messagesForWorkersSub.unsubscribe();
  }
}
