<div
    fxLayout="column"
    style="height: 90%; width: 100%"
>
    <div
        fxLayoutGap="10px"
        fxLayout="row"
        fxFlex
        style="height: 100%; width: 100%"
    >
        <div
            fxFlex="50"
            fxLayout="column"
            style="border:2px solid #673ab7;"
        >
            <h3>Przychody</h3>
            <div class="ml-2">
                <h3>Ilość miesięcy: {{numberOfMonthsIncomes}}
                </h3>
                <div
                    style="overflow-y: auto"
                    fxLayout="column"
                >
                    <div
                        (click)="item.selected = !item.selected"
                        [style.background-color]="helpers.isEven(i) && !item.selected ? '#ffe0b2' : !helpers.isEven(i) && !item.selected  ?'#ffcc80' : '#ef9a9a' "
                        *ngFor="let item of summarizedIncomes let i = index"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        class="pt-1 pb-1"
                    >
                        <span class="ml-3">{{item.name}}:</span>
                        <span style="margin-right: 10vw ;">{{item.value}}</span>
                    </div>
                </div>
                <hr />
                <h3>Razem Przychody: {{allIncomes}}</h3>
                <h2>Stany:</h2>
                <div
                    style="width:100%"
                    fxLayout="column"
                >
                    <span
                        style="width:100%"
                        class="pt-1 pb-1"
                        [style.background-color]="helpers.isEven(i) && !item.selected ? '#bbdefb' : !helpers.isEven(i) && !item.selected  ?'#90caf9' : '#ef9a9a' "
                        (click)="item.selected = !item.selected"
                        *ngFor="let item of finances.states let i = index"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                    >
                        <span>{{item.name + ': '}}</span>
                        <span style="margin-right: 10vw ;">{{item.value}}</span>

                    </span>
                </div>

            </div>
        </div>
        <div
            fxFlex="50"
            fxLayout="column"
            style="border:2px solid #673ab7;"
        >
            <h3>Wydatki</h3>
            <h3>Ilość miesięcy: {{numberOfMonthsExpenses}}
            </h3>
            <div
                style="overflow-y: auto"
                fxLayout="column"
            >
                <div
                    (click)="item.selected = !item.selected"
                    [style.background-color]="helpers.isEven(i) && !item.selected ? '#b39ddb' : !helpers.isEven(i) && !item.selected  ?'#ede7f6' : '#ef9a9a' "
                    *ngFor="let item of summarizedExpenses let i = index"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    class="pt-1 pb-1"
                >
                    <span class="ml-3">{{item.name}}:</span>
                    <span>{{numberOfMonthsExpenses + ' * '}} {{item.value / numberOfMonthsExpenses | number : '1.0-0'}}
                    </span>
                    <span style="margin-right: 10vw ;">{{item.value}}</span>
                </div>
            </div>
            <hr />
            <h3>Razem Wydatki: {{allExpenses}}</h3>
        </div>
    </div>
    <div
        class="mt-3"
        style="width:100%"
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayoutAlign="center center"
    >
        <h1>Wynik:{{result}}</h1>
        <h1>Wynik Firma:{{companyResult}}</h1>
    </div>
    <button
        class="mt-2"
        style="width:100%"
        mat-raised-button
        color="primary"
        mat-dialog-close
    >Zamknij
    </button>
</div>
