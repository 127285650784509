<div
    style="height: 80vh;"
    fxLayoutAlign="start"
    fxLayout="column"
>
    <h1>Utwórz PDF</h1>
    <div
        fxFlex
        fxLayout="row"
        fxLayout.lt-md="column"
    >
        <div
            fxLayout="column"
            fxFlex
        >
            <div
                style="overflow-y: auto; overflow-x: hidden;"
                class="p-2"
            >
                <div
                    #childrenParams
                    *ngIf="data.set?.children"
                >
                    <mat-checkbox [(ngModel)]="glossary">Słowniczek</mat-checkbox>
                </div>
                <mat-chip-list
                    class="mb-2"
                    *ngIf="data?.fromSets?.length > 1"
                >
                    <div *ngFor="let type of (data.set.type !== 'free' && !data.set.children? sentencesPdfTypes : freeSetPdfTypes)">
                        <mat-chip
                            *ngIf="type.multiSet"
                            selectable
                            [selected]="type.selected"
                            (click)="selectPdfType(type, set)"
                            color="primary"
                        >{{type.displayName}}
                        </mat-chip>
                    </div>
                </mat-chip-list>
                <mat-accordion>
                    <mat-expansion-panel
                        class="mt-1"
                        *ngFor="let set of data.fromSets"
                    >
                        <mat-expansion-panel-header>{{set.name}}</mat-expansion-panel-header>
                        <div
                            fxLayout="column"
                            style="width: 100%;"
                            fxLayoutGap="10px"
                        >
                            <span *ngIf="!data.avalibleOptions">Zadania zdefiniowane</span>
                            <mat-divider
                                *ngIf="!data.avalibleOptions"
                                class="mb-2"
                            ></mat-divider>
                            <mat-chip-list class="mb-2">
                                <div *ngFor="let type of (childrenMode? childrenPdfTypes: data.set.type !== 'free' ? sentencesPdfTypes : data.set.type == 'free'? freeSetPdfTypes : null )">
                                    <mat-chip
                                        *ngIf="type.preset && !type.inOrder && !type.multiSet"
                                        selectable
                                        [selected]="type.selected"
                                        (click)="selectPdfType(type, set)"
                                        color="primary"
                                    >{{type.displayName}}
                                    </mat-chip>
                                </div>
                            </mat-chip-list>
                        </div>
                        <div
                            fxLayout="column"
                            style="width: 100%;"
                        >
                            <span *ngIf="!data.avalibleOptions">Zadania pojedyncze</span>
                            <mat-divider
                                *ngIf="!data.avalibleOptions"
                                class="mb-2"
                            ></mat-divider>
                        </div>
                        <mat-chip-list>
                            <div *ngFor="let type of (childrenMode? childrenPdfTypes: data.set.type !== 'free' ? sentencesPdfTypes : data.set.type == 'free'? freeSetPdfTypes : null )">
                                <mat-chip
                                    class="m-2"
                                    *ngIf="!type.preset  && !type.multiSet && !type.inOrder && type.name !== 'children-dictionary'"
                                    selectable
                                    [selected]="type.selected"
                                    (click)="selectPdfType(type, set)"
                                    color="primary"
                                >{{type.displayName}}
                                </mat-chip>
                            </div>
                        </mat-chip-list>
                        <div
                            fxLayout="column"
                            style="width: 100%;"
                        >
                            <span *ngIf="!data.avalibleOptions">Zadania pojedyncze uporządkowane (nie dodawaj 2 takich samych
                ćwiczeń tego typu)
                            </span>
                            <mat-divider
                                *ngIf="!data.avalibleOptions"
                                class="mb-2"
                            ></mat-divider>
                        </div>
                        <mat-chip-list>
                            <div *ngFor="let type of (childrenMode? childrenPdfTypes: data.set.type !== 'free' ? sentencesPdfTypes : data.set.type == 'free'? freeSetPdfTypes : null )">
                                <mat-chip
                                    *ngIf="type.inOrder"
                                    selectable
                                    [selected]="type.selected"
                                    (click)="selectPdfType(type, set)"
                                    color="primary"
                                >{{type.displayName}}
                                </mat-chip>
                            </div>
                        </mat-chip-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div fxFlex></div>
            <!-- <button
                mat-raised-button
                color="primary"
                *ngIf="data.group && filesToSendService.filesToSend.length > 0"
                (click)="openSendEmailDialog()"
            >Wyślij emailem
            </button> -->
            <button
                style="width: 100%;"
                (click)=" generatePdf2()"
                mat-flat-button
                color="primary"
            >
                <div
                    fxLayoutAlign="center center"
                    fxLayout="row"
                >
                    <span *ngIf="!pdf2Service.creatingPdf">Utwórz Pdf</span>
                    <mat-spinner
                        diameter="20"
                        color="accent"
                        *ngIf="pdf2Service.creatingPdf"
                    ></mat-spinner>
                    <span *ngIf="pdf2Service.creatingPdf">Tworzę pdf...</span>
                </div>
            </button>
            <button
                class="mt-2"
                mat-stroked-button
                color="primary"
                mat-dialog-close
            >Zamknij
            </button>
        </div>
        <div
            fxFlex="50"
            style="height: 80vh; overflow-y: auto;"
            fxLayout="column"
        >
            <button
                (click)="addPage()"
                mat-raised-button
                color="primary"
            >Dodaj stronę
            </button>
            <div
                class="m-2"
                *ngFor="let page of pages let i= index"
                (click)="selectPage(page)"
                fxLayout="column"
                fxLayoutAlign="start center"
                [style.border]="!page.selected? '3px solid #673ab7' : '3px solid #ffc107'"
            >
                <span>{{'Strona ' + (i + 1)}}</span>
                <div
                    class="p-2"
                    fxLayout="row"
                    style="width: 100%;"
                >
                    <div
                        fxFlex="90"
                        fxLayoutAlign="center center"
                    >
                        <div
                            style="width: 100%;"
                            fxLayout="column"
                            fxLayoutAlign="center center"
                            fxLayoutGap="5px"
                        >
                            <span
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                class="p-1"
                                style="
                  width: 100%;
                  border-radius: 10px;
                  background-color: #673ab7;
                  color: white;
                "
                                *ngFor="let task of page.pageToSend"
                            >
                                <div
                                    style="width: 100%;"
                                    fxLayout="column"
                                >
                                    <span
                                        style="font-weight: bolder;"
                                        class="ml-2"
                                        fxFlex
                                    >{{task.displayName}}
                                    </span>
                                    <span
                                        style="font-size: 0.8em;"
                                        class="ml-2"
                                        fxFlex
                                    >{{task.setName}}
                                    </span>
                                </div>
                                <mat-icon
                                    class="pointer"
                                    (click)="removeTaskFromPage(task, page)"
                                >delete_outline
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                    <div
                        fxFlex
                        fxLayoutAlign="center center"
                    >
                        <button
                            (click)="removePage(page); $event.preventDefault(); $event.stopPropagation()"
                            mat-icon-button
                            color="warn"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
