import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import sortArray from 'sort-array';
import compare from 'compare-arrays';
import clone from 'clone-deep';
const findDuplicates = require('array-find-duplicates');
const uniqid = require('uniqid');
import randomItem from 'random-item';
import uniqueRandomArray from 'unique-random-array';
import { addHours, isDate } from 'date-fns';
const arrayPower = require('array-power');
const replaceAll = require('just-replace-all');
var clg = require('crossword-layout-generator');
var scramble = require('scramble');

const arrayHelper = require('array-helper-functions');
const WordSearch = require('@blex41/word-search');

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  accents = [
    'á',
    'à',
    'â',
    'ä',
    'ã',
    'é',
    'è',
    'ê',
    'ë',
    'í',
    'ì',
    'î',
    'ï',
    'ó',
    'ò',
    'ô',
    'ö',
    'õ',
    'ú',
    'ù',
    'û',
    'ü',
    'ñ',
    'ç',
    'ß',
    'ă',
    'Ă',
    'ĕ',
    'Ĕ',
    'ğ',
    'Ğ',
    'ĭ',
    'Ĭ',
    'ŏ',
    'Ŏ',
    'œ',
    'Œ',
    'ř',
    'Ř',
    'ş',
    'Ş',
    'ț',
    'Ț',
    'ů',
    'Ů',
    'ű',
    'Ű',
    'ź',
    'Ź',
    'ż',
    'Ż',
  ];
  accentMap = {
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
  };
  colors100 = [
    '#ffcdd2',
    '#e1bee7',
    '#d1c4e9',
    '#c5cae9',
    '#b3e5fc',
    '#b2ebf2',
    '#b2dfdb',
    '#c8e6c9',
    '#dcedc8',
    '#f0f4c3',
    '#fff9c4',
    '#ffecb3',
    '#ffe0b2',
  ];
  colors400 = [
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#78909c',
  ];
  constructor() {
    this.shuffle(this.colors100);
  }
  makeid(length?) {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // const charactersLength = characters.length;
    // for (let i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    result = uniqid();
    return result;
  }
  createWordSearch(options) {
    let result;
    result = new WordSearch(options);

    return result;
  }
  flatenArray(array) {
    return arrayHelper.flatten(array);
  }
  enterFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  }
  replaceAllString(string, searchFor, replaceTo) {
    return replaceAll(string, searchFor, replaceTo);
  }

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  lightenColor(hex, amount) {
    // Convert the hex color code to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Increase the value of each RGB component
    r = Math.min(Math.floor(r + amount), 255);
    g = Math.min(Math.floor(g + amount), 255);
    b = Math.min(Math.floor(b + amount), 255);

    // Convert the updated RGB values back to a hex color code
    const newHex = ((r << 16) | (g << 8) | b).toString(16);
    return '#' + newHex.padStart(6, '0');
  }
  createCrossword(items) {
    return clg.generateLayout(items);
  }
  getRandomNumber(min, max) {
    let result;
    result = Math.random() * (max - min) + min;
    return Math.round(result);
  }
  replaceWordsWithPercentage(sentence, percentage) {
    // Split the sentence into an array of words
    let words = sentence.split(' ');

    // Calculate the number of words to replace
    let numWordsToReplace = Math.ceil((percentage / 100) * words.length);
    numWordsToReplace = 1;

    // Filter words that have at least four letters
    let filteredWords = words.filter((word) => word.length >= 4);

    // Check if there are enough words with at least four letters for replacement
    const hasEnoughWords = filteredWords.length > 0;

    // Replace the selected word and other random words
    filteredWords = this.shuffle(filteredWords);
    const randomIndex = Math.floor(Math.random() * filteredWords.length);
    let mark = this.extractLastPunctuation(filteredWords[randomIndex]);
    if (!mark) {
      mark = '';
    }
    const replacedSentence = sentence.replace(
      filteredWords[randomIndex],
      '______________' + mark,
    );

    // Join the replaced words back into a sentence, preserving the punctuation marks

    return replacedSentence;
  }

  replaceRandomWords(input) {
    const words = input.split(/\s+/);
    const longWordsIndices = [];

    words.forEach((word, index) => {
      if (word.length >= 5) {
        longWordsIndices.push(index);
      }
    });

    if (longWordsIndices.length < 3) {
      return { modifiedText: input, replacedWords: '' };
    }

    const segmentLength = Math.floor(longWordsIndices.length / 3);
    const randomIndices = [
      longWordsIndices[Math.floor(Math.random() * segmentLength)],
      longWordsIndices[
        Math.floor(Math.random() * segmentLength) + segmentLength
      ],
      longWordsIndices[
        Math.floor(Math.random() * segmentLength) + 2 * segmentLength
      ],
    ];

    let replacedWords = [];
    randomIndices.forEach((index) => {
      let mark = this.extractLastPunctuation(words[index]);

      const word = this.removeSpecialCharsAndSpaces(words[index]);
      replacedWords.push(word.toLowerCase());
      if (!mark) {
        mark = '';
      }
      words[index] = '_____________' + mark;
    });
    replacedWords = this.shuffle(replacedWords);
    return {
      modifiedText: words.join(' '),
      replacedWords: replacedWords.join(' ~ '),
    };
  }
  extractLastPunctuation(str) {
    // Regular expression to match any punctuation at the end of a string
    const match = str.match(/[.,!?;:"'-]+$/);

    // Return the matched punctuation or null if none is found
    return match ? match[0] : null;
  }
  scramble(string) {
    return scramble(string).split(' ').join('');
  }
  removeTextBetweenParenths(string) {
    return string.replace(/ *\([^)]*\) */g, '');
  }
  checkIos() {
    const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
    return iOS;
  }
  getRandomItemFromArray(array, multiple?, numberOfItems?) {
    let result;
    if (multiple) {
      result = randomItem.multiple(array, numberOfItems);
    } else {
      result = randomItem(array);
    }

    return result;
  }
  removeAccentFromString(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  removeSpecialCharsAndSpaces(str) {
    return this.removeAccentFromString(str)
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  }
  replaceLettersWithUnderscore(str) {
    return str.replace(/[a-zA-Z]/g, '_');
  }
  replaceLettersWithDoubleUnderscore(str) {
    return str.replace(/[a-zA-Z]/g, '__');
  }
  convertGoogleDriveLink(link) {
    let id = link.replace('https://drive.google.com/file/d/', '');
    id = id
      .replace('/view?usp=share_link', '')
      .replace('/view?usp=sharing', '')
      .replace('/view?usp=drivesdk', '');

    const directLink =
      'https://drive.google.com/uc?id=' + id + '&export=download';
    console.log(
      '🚀 ~ file: helpers.service.ts ~ line 125 ~ HelpersService ~ convertGoogleDriveLink ~ directLink',
      directLink,
    );
    return directLink;
  }
  getPolishDayName() {
    const date = new Date();
    const dayIndex = date.getDay();
    const polishDays = [
      'Niedziela',
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
    ];

    return polishDays[dayIndex];
  }
  getUniqueRandomItemsFromArray(array, numberOfItems) {
    let result = [];
    let temp = [];
    const random = uniqueRandomArray(array);
    for (let index = 0; index < numberOfItems; index++) {
      result.push(random());
    }
    return result;
  }
  hexToRgba(hex, alpha) {
    // Remove the '#' character if present
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1);
    }

    // Check if the hex code is shorthand (e.g., #abc)
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    // Parse the hex code to RGB values
    var bigint = parseInt(hex, 16);
    var red = (bigint >> 16) & 255;
    var green = (bigint >> 8) & 255;
    var blue = bigint & 255;

    // Calculate the alpha value in the range of 0-1
    alpha = parseFloat(alpha);
    alpha = Math.min(Math.max(alpha, 0), 1);

    // Return the RGBA value
    return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')';
  }
  removeDuplicatesObjbyId(array) {
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item.id == itemToCheck.id)) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }
  extractTextFromHTML(html) {
    let text = '';
    let tag = false;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<') {
        tag = true;
      } else if (html[i] === '>') {
        tag = false;
      } else if (!tag) {
        text += html[i];
      }
    }
    console.log(
      '🚀 ~ file: helpers.service.ts:207 ~ HelpersService ~ extractTextFromHTML ~ text:',
      text,
    );
    return text;
  }
  removeDuplicatesObjbyProp(array, prop) {
    if (!array) return [];
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item[prop] == itemToCheck[prop])) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }

  getItemsForPack(itemsPacks, _allItems, numberOfItems) {
    let allItems = this.removeDuplicatesObjbyId(_allItems);
    const random = uniqueRandomArray(allItems);
    let usedWords = this.flatenArray(this.clone(itemsPacks));
    usedWords = this.removeDuplicatesObj(usedWords);
    let itemsToUse = allItems.filter(
      (item: any) => !usedWords.some((usedWord) => item?.id == usedWord?.id),
    );

    if (itemsToUse.length >= numberOfItems) {
      let result = this.getUniqueRandomItemsFromArrayOfObjects(
        itemsToUse,
        numberOfItems,
      );
      return result;
    } else {
      let result = this.getUniqueRandomItemsFromArrayOfObjects(
        itemsToUse,
        itemsToUse.length,
      );
      return result;
    }
  }
  getUniqueNumberedItemsFromArrayOfObjects(array, numberOfItems) {
    let result: any = [];
    let temp = [];
    // const random = uniqueRandomArray(array);
    for (let index = 0; index < numberOfItems; index++) {
      // while (result.length < numberOfItems - 1) {
      // const itemToPush = random();
      const itemToPush = array[index];

      if (result.indexOf(itemToPush) === -1) {
        //if array is smaller than number of items we have a problem
        result.push(itemToPush);
      }
      // }
    }
    return result;
  }
  getUniqueRandomItemsFromArrayOfObjects(array, numberOfItems) {
    let result = [];
    let temp = [];
    const random = uniqueRandomArray(array);
    while (result.length < numberOfItems) {
      const itemToPush = random();
      if (result.indexOf(itemToPush) === -1) {
        //if array is smaller than number of items we have a problem
        result.push(itemToPush);
      }
    }
    return result;
  }
  isEven(n) {
    return n % 2 == 0;
  }
  checkArrayDuplicates(array) {
    const duplicates = findDuplicates(array);
    if (duplicates.lenght > 0) {
      return true;
    } else {
      return false;
    }
  }
  chunk(arr, chunkSize) {
    if (chunkSize <= 0) {
      throw new Error('Invalid chunk size');
    }
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  disassembleObject(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object.split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }

  disassembleObjectForBook(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object.split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }

  pushToArray(array, element) {
    array.push(element);
    return array;
  }
  removeDuplicatesObj(arr) {
    return arr?.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i,
    );
  }
  arrayDifference(arrA, arrB) {
    return arrA
      .filter((x) => !arrB.includes(x))
      .concat(arrB.filter((x) => !arrA.includes(x)));
  }
  removeDuplicatesSingle(arr) {
    arr = arr.filter(
      (item, index) => !arr.slice(0, index).find((e) => e === item),
    );
    return arr;
  }
  convertFromStringToDate(responseDate) {
    let result;
    if (!isDate(responseDate)) {
      let iso = responseDate.includes('.000Z');
      responseDate = responseDate.replace('.000Z', '');
      let dateComponents = responseDate.split('T');
      let datePieces = dateComponents[0].split('-');
      let timePieces = dateComponents[1].split(':');
      // return { date: datePieces, time: timePieces };
      const dateToSend = new Date(
        datePieces[0],
        datePieces[1] - 1,
        datePieces[2],
        timePieces[0],
        timePieces[1],
        timePieces[2] ? timePieces[2] : null,
      );
      if (iso) {
        result = addHours(dateToSend, 2);
      }
      if (!iso) {
        result = dateToSend;
      }
    }
    if (isDate(responseDate)) {
      result = responseDate;
    }
    return result;
  }
  formatTime(timeString) {
    let [hour, minute] = timeString.split(':');
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    return `${hour}:${minute}`;
  }
  splitArrayByProperty(arr, key) {
    return arr
      .reduce((acc, cur) => {
        acc[cur[key]] = [...(acc[cur[key]] || []), cur];
        return acc;
      }, [])
      .filter(Boolean);
  }

  removeElementFromArray(array, element) {
    const numberToRemove = array.findIndex((e) => e == element);
    array.splice(numberToRemove, 1);
    return array;
  }
  replaceElementFromArrayObj(array, property, oldValue, newValue, equals) {
    if (equals) {
      const numberTReplace = array.findIndex((e) => e[property] == oldValue);
      array[numberTReplace] = newValue;
    } else {
      const numberTReplace = array.findIndex((e) => e[property] !== oldValue);
      array[numberTReplace] = newValue;
    }
    return array;
  }
  setColor100(colorNumber) {
    if (colorNumber < this.colors100.length) {
      return this.colors100[colorNumber];
    } else {
      colorNumber = 0;
      return this.colors100[colorNumber];
    }
  }
  getUniqueArrayOfObjectsById(array) {
    let result = [];
    result = array?.filter(
      (a, i) => array.findIndex((s) => a.id === s.id) === i,
    );
    // array.forEach((item) => {
    //   if (!result.some((_item) => item.id == item.id)) {
    //     result.push(item);
    //   }
    // });
    return result;
  }

  clone(item) {
    return clone(item);
  }
  sortArrayByProperty(array, sortProperty, order: 'asc' | 'desc') {
    let result = [];
    if (array?.length > 0) {
      result = sortArray(array, {
        by: sortProperty,
        order,
      });
    }

    return result;
  }
  sortArraySimple(array, order: 'asc' | 'desc') {
    let result = [];
    if (array?.length > 0) {
      result = sortArray(array, order);
    }

    return result;
  }

  compareArrays(array1, array2) {
    return compare(array1, array2);
  }
  pushPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property].push(value);
    return array;
  }
  unshiftPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property].unshift(value);
    return array;
  }
  setPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property] = value;
    return array;
  }
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  shuffleArrayOfObjects(array) {
    return arrayPower.shuffle(array);
  }
}
