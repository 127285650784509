import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Pdf2PreviewComponent } from './pdf2/pdf2-preview/pdf2-preview.component';
import { AuthenticationService } from './shared/services/authentication.service';
import { FilesToSendService } from './shared/services/files-to-send.service';
import { HelpersService } from './shared/services/helpers.service';
import { VersionService } from './shared/services/version.service';

@Injectable({
  providedIn: 'root',
})
export class Pdf2Service {
  creatingPdf = false;

  url: string;
  constructor(
    private filesToSendService: FilesToSendService,
    private dialog: MatDialog,
    private http: HttpClient,
    private authService: AuthenticationService,
    private helpers: HelpersService,
    private versionService: VersionService,
  ) {
    // this.url = this.versionService.url + '/api/pdf/';
    this.url = 'https://backend-heavy.linget.it' + '/api/pdf/';
  }
  generatePdf(freeset, type, _pages, mixedItems?, requestType?, group?) {
    let pages = this.helpers.clone(_pages);
    pages.forEach((page) => {
      page.pageToSend.forEach((task) => {
        delete task.setName;
        delete task.color;
      });
    });

    this.creatingPdf = true;
    let pagesToSend = pages.map((page) => {
      return page.pageToSend;
    });
    console.log(
      '🚀 ~ file: pdf2.service.ts ~ line 37 ~ Pdf2Service ~ pagesToSend ~ pagesToSend',
      pagesToSend,
    );

    this.http
      .post(
        this.url + 'generatepdf',
        {
          freeset: freeset,
          type: type,
          mixeditems: mixedItems,
          pages: JSON.stringify(pagesToSend),
        },

        {
          headers: {
            Authorization: `Bearer ${this.authService.getToken()}`,
            Accept: 'application/pdf',
          },
          responseType: 'arraybuffer' as 'json',
        },
      )
      .subscribe((response: any) => {
        let file = new Blob([response], { type: 'application/pdf' });
        this.filesToSendService.filesToSend = [];
        this.filesToSendService.filesToSend.push(file);
        var fileURL = URL.createObjectURL(file);

        // window.open(fileURL);
        this.dialog.open(Pdf2PreviewComponent, {
          height: '90vh',

          data: { file: fileURL, pdf: file, group: group },
        });
        this.creatingPdf = false;
      });
  }
}
