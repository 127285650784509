import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AuthenticationService } from "../shared/services/authentication.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserDetailsService } from "./user-details.service";
import { TopBarVisibilityService } from "./top-bar-visibility.service";
import { TeacherMainListService } from "./teacher/teacher-main-list.service";
import { VersionService } from "../shared/services/version.service";
import { UpdateService } from "../shared/services/update.service";
import { CheckUsersTasksService } from "../shared/services/check-users-tasks.service";
import { RealTimeCommunicationService } from "../shared/services/real-time-communication.service";
import { ErrorLoggingService } from "../shared/services/error-logging.service";
import { UsersServiceService } from "../shared/services/users-service.service";
import { Pdf2Service } from "../pdf2.service";
import { DOCUMENT } from "@angular/common";
import { WorkersAvatarsService } from "../shared/services/workers-avatars.service";
import { MatDialog } from "@angular/material/dialog";
import { FinancesStatsComponent } from "./owner/finances-stats/finances-stats.component";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  firstMenuGroupExpanded = false;
  secondMenuGroupExpanded = false;
  nameSub: Subscription;
  componentName;
  profileSub;
  topBarSub;
  apm;
  showToolbar = true;
  userDetails;
  wonderPush;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  interval;
  tasksChangesSub: Subscription;
  tasksNumber: number;
  window: Window & typeof globalThis;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthenticationService,
    private realTimeCommunicationService: RealTimeCommunicationService,
    private spinner: NgxSpinnerService,
    public userDetailsService: UserDetailsService,
    private topBarVisibilityService: TopBarVisibilityService,
    public teacherMainListService: TeacherMainListService,
    private router: Router,
    private logger: ErrorLoggingService,
    public versionService: VersionService,
    private pdfService: Pdf2Service,
    private updateService: UpdateService,
    private workersAvatarsService: WorkersAvatarsService,
    private checkUsersTasksService: CheckUsersTasksService,
    private dialog: MatDialog
  ) {
    this.window = this.document.defaultView;
    console.log(this.document);
    console.log(this.window);

    this.profileSub = this.auth.profile().subscribe((user) => {

      this.userDetails = user;
      const text = this.userDetails.role + " logged in!";
      this.logger.info(text, {
        name: this.userDetails.name,
      });
      if (
        this.versionService.environment !== "dev" &&
        (this.userDetails?.role == "owner" ||
          this.userDetails?.role == "teacher" ||
          this.userDetails?.role == "office")
      ) {
        this.userDetails.avatar = this.workersAvatarsService.getAvatarURL(
          this.userDetails.name
        );
        console.log(
          "🚀 ~ file: profile.component.ts ~ line 147 ~ ProfileComponent ~ this.profileSub=this.auth.profile ~ this.userDetails.avatar",
          this.userDetails.avatar
        );
        this.wonderPush = this.window.WonderPush || [];
        this.wonderPush.push([
          "init",
          {
            webKey:
              "fcbcf61b594efb1597f078f3085f11264bc5d96a4b90d0c0304bafd1d495e0e9",
            userId: this.userDetails.id
              ? this.userDetails.id
              : this.userDetails._id,
            applicationVersion: this.versionService.version,
            subscriptionBell: true,
          },
        ]);
        window.addEventListener("load", function () {
          navigator.serviceWorker
            .register("/service-worker.js")
            .then(function (registration) {
              registration.onupdatefound = function () {
                console.log("Saving assets for offline caching!");

                const installingWorker = registration.installing;
                installingWorker.onstatechange = function () {
                  switch (installingWorker.state) {
                    case "installed":
                      if (!navigator.serviceWorker.controller) {
                        console.log("Caching complete!");
                      }
                      break;

                    case "redundant":
                      throw Error(
                        "The installing service worker became redundant."
                      );
                  }
                };
              };
            });
        });
        window.addEventListener("WonderPushEvent", function (event) {
          console.log(
            "🚀 ~ file: profile.component.ts ~ line 109 ~ ProfileComponent ~ window.addEventListener ~ event",
            event
          );
        });
      }

      this.versionService.setVersion();
      // rg4js('setUser', {
      //   identifier: this.userDetails._id,
      //   isAnonymous: false,
      //   email: this.userDetails.email,
      //   fullName: this.userDetails.name,
      // });

      this.userDetailsService.storeUserDetails(this.userDetails);
      // httpSpan.end();
      // transaction.end();
    });
  }
  ngOnInit(): void {
    this.tasksChangesSub = this.realTimeCommunicationService
      .messagesForWorkersListener()
      .subscribe((message: any) => {
        if (message == "updateTasks") {
          if (
            this.userDetailsService.getSimpleUserDetails().role === "office" ||
            this.userDetailsService.getSimpleUserDetails().role === "teacher" ||
            this.userDetailsService.getSimpleUserDetails().role === "owner"
          ) {
            this.tasksNumber = 0;
            this.tasksNumber = this.userDetails?.userData?.tasks?.length;
            if (
              this.userDetailsService.getSimpleUserDetails().role === "office"
            ) {
              this.checkUsersTasksService.checkUsersTasks(
                this.userDetails._id,
                this.userDetails.userData.tasks.length,
                "sekretariat@lingking.pl"
              );
            } else {
              this.checkUsersTasksService.checkUsersTasks(
                this.userDetails._id,
                this.userDetails?.userData?.tasks?.length
                  ? this.userDetails?.userData?.tasks?.length
                  : 0
              );
            }
          }
        }
      });

    if ("storage" in navigator && "estimate" in navigator.storage) {
      navigator.storage.estimate().then(function (estimate) {
        console.log(
          `Using ${estimate.usage / 1024 / 1024} out of ${estimate.quota / 1024 / 1024
          } Mbytes.`
        );
      });
    }
    this.updateService.checkUpdates();

    if (this.router.url === "/profile") {
      this.router.navigateByUrl("/");
    }
    this.topBarSub = this.topBarVisibilityService
      .toolbarVisible()
      .subscribe((visibility: boolean) => {
        this.showToolbar = visibility;
      });
    this.spinner.hide();

    const loc = window.location.hostname;
    if (loc === "test.lingking.com.pl") {
      alert("wersja testowa!");
    }
  }

  logout() {
    this.tasksChangesSub.unsubscribe();

    clearInterval(this.interval);
    this.auth.logout();
  }
  openFinanceStats() {
    if (this.userDetailsService.owner) {
      this.dialog.open(FinancesStatsComponent, { width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh' });
    }

  }
  ngOnDestroy(): void {
    this.tasksChangesSub.unsubscribe();
    clearInterval(this.interval);
    this.profileSub.unsubscribe();
  }
}
