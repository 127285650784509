import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  url;
  chatMessagesUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  translateToPolishWithChat(message) {
    this.http
      .post<{ sets: any }>(
        this.url + '/api/chat/translate-to-polish-with-chat',
        {
          message: message,
        },
      )
      .pipe()
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: chat.service.ts:44 ~ ChatService ~ .subscribe ~ response',
          response,
        );
        const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;
        console.log(
          '🚀 ~ file: chat.service.ts:35 ~ ChatService ~ .subscribe ~ koszt:',
          costPLN + ' groszy',
        );
        this.chatMessagesUpdated.next(response);
      });
  }
  correctPolishText(message) {
    this.http
      .post<{ sets: any }>(this.url + '/api/chat/correct-polish-text', {
        message: message,
      })
      .pipe()
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: chat.service.ts:44 ~ ChatService ~ .subscribe ~ response',
          response,
        );
        const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;
        console.log(
          '🚀 ~ file: chat.service.ts:35 ~ ChatService ~ .subscribe ~ koszt:',
          costPLN + ' groszy',
        );
        this.chatMessagesUpdated.next(response);
      });
  }
  chatMessagesListener() {
    return this.chatMessagesUpdated.asObservable();
  }
}
