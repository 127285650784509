import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import {
    CanvasWhiteboardComponent,

} from "ng2-canvas-whiteboard";

import { GroupsService } from "src/app/shared/services/groups.service";
import { Subscription } from "rxjs";
import { CreateReportComponent } from "../components/create-report/create-report.component";
import { CoursesPlanComponent } from "src/app/courses/courses-plan/courses-plan.component";
import { PiratesGameComponent } from "src/app/profile/teacher/classroom-games/pirates-game/pirates-game.component";
import { FinancesStatsComponent } from "src/app/profile/owner/finances-stats/finances-stats.component";
import { LessonsComponent } from '../../lessons/lessons.component';
import { PlatformGameTestComponent } from '../../live/boardgame/platform-game-test/platform-game-test.component';
import { QuizWriteCheckComponent } from '../../profile/student/quiz/quiz-write-check/quiz-write-check.component';
import { TestBedComponent } from './test-bed/test-bed.component';
import { LessonActivitiesAddEditComponent } from '../../profile/teacher/teacher-activities/lesson-activities-add-edit/lesson-activities-add-edit.component';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { WorkHoursComponent } from '../../profile/teacher/work-hours/work-hours.component';
import { HelpersService } from "../services/helpers.service";
import { SetTextConstructorComponent } from "src/app/database/sets/set-text-constructor/set-text-constructor.component";

@Component({
    selector: "app-simple-test",
    viewProviders: [CanvasWhiteboardComponent],

    templateUrl: "./simple-test.component.html",
    styleUrls: ["./simple-test.component.css"],
})
export class SimpleTestComponent {
    showChat = false
    activity: {
        "id": "6368e13cdec14d40a94fcb50",
        "name": "Zbiór dobrych praktyk przy nauczaniu dorosłych",
        "ageGroups": [
            {
                "value": "4 lata",
                "selected": false
            },
            {
                "value": "przedszkole",
                "selected": false
            },
            {
                "value": "1 klasa",
                "selected": false
            },
            {
                "value": "2 klasa",
                "selected": false
            },
            {
                "value": "3 klasa",
                "selected": false
            },
            {
                "value": "4 klasa",
                "selected": false
            },
            {
                "value": "5 klasa",
                "selected": false
            },
            {
                "value": "6 klasa",
                "selected": false
            },
            {
                "value": "7 klasa",
                "selected": false
            },
            {
                "value": "8 klasa",
                "selected": false
            },
            {
                "value": "1 klasa LT",
                "selected": false
            },
            {
                "value": "2 klasa LT",
                "selected": false
            },
            {
                "value": "3 klasa LT",
                "selected": false
            },
            {
                "value": "4 klasa LT",
                "selected": false
            },
            {
                "value": "Dorośli",
                "selected": false
            }
        ],
        "author": {
            "name": "",
            "id": "",
            "role": ""
        },
        "comments": [],
        "description": "",
        "unfinished": true,
        "hints": [],
        "numbersOfStudents": [
            {
                "value": "1 osoba",
                "selected": false
            },
            {
                "value": "2 osoby",
                "selected": false
            },
            {
                "value": "3 osoby",
                "selected": false
            },
            {
                "value": "4 osoby",
                "selected": false
            },
            {
                "value": "5 osób",
                "selected": false
            },
            {
                "value": "6 osób",
                "selected": false
            },
            {
                "value": "7 osób",
                "selected": false
            },
            {
                "value": "8 osób",
                "selected": false
            },
            {
                "value": "więcej",
                "selected": false
            },
            {
                "value": "parzyście",
                "selected": false
            },
            {
                "value": "nieparzyście",
                "selected": false
            }
        ],
        "skills": [
            {
                "value": "Czytanie",
                "selected": false
            },
            {
                "value": "Pisanie",
                "selected": false
            },
            {
                "value": "Komunikacja",
                "selected": false
            },
            {
                "value": "Gramatyka",
                "selected": false
            },
            {
                "value": "Dowolnie",
                "selected": false
            }
        ],
        "timesOfActivity": [
            {
                "value": "5 minut",
                "selected": false
            },
            {
                "value": "10 minut",
                "selected": false
            },
            {
                "value": "15 minut",
                "selected": false
            },
            {
                "value": "20 minut",
                "selected": false
            },
            {
                "value": "25 minut",
                "selected": false
            },
            {
                "value": "30 minut",
                "selected": false
            }
        ],
        "variants": [],
        "pictures": [],
        "videos": [],
        "pdfs": [
            "https://lingkingassets.fra1.digitaloceanspaces.com/pdfs/Zbio%CC%81r%20dobrych%20praktyk%20w%20nauczaniu%20doros%C5%82ych.pdf"
        ],
        "tags": [
            {
                "tag": "training"
            }
        ]
    }
    constructor(
        private spinner: NgxSpinnerService,
        private dialog: MatDialog,
        private helpers: HelpersService,
        private bottomSheet: MatBottomSheet,

    ) {
        this.spinner.hide();
        // this.openFinanceStats()
    }
    ngOnInit(): void {
        // this.comet = this.window.comet
        // this.dialog.open(WorkHoursComponent, { width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh' });
this.openTextConstructor()

    }
    openFinanceStats() {
        this.dialog.open(FinancesStatsComponent, { width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh' });
    }

    openTextConstructor() {
        const dialogRef = this.dialog.open(SetTextConstructorComponent, {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
        });
        dialogRef.afterClosed().subscribe((result) => {
        console.log("🚀 ~ file: set-content.component.ts:267 ~ SetContentComponent ~ dialogRef.afterClosed ~ result:", result)
        }
        );
    }
    test() {
        const descriptiveSentence = "It's OK for Anna to invite friends to her birthday party.";
        const answerSentences = [
            'Anna can invite friends to her birthday party.',
            "Anna mustn't invite friends to her birthday party.",
            'Anna has to invite friends to her birthday party.',
            "Anna doesn't need to invite friends to her birthday party."
        ];

        const transformedExercise = this.transformExercise(descriptiveSentence, answerSentences);

        console.log(transformedExercise);
    }
    transformExercise(descriptiveSentence: string, answerSentences: string[]) {
        const sentences = [
            'Anna can invite friends to her birthday party.',
            'Anna mustn\'t invite friends to her birthday party.',
            'Anna has to invite friends to her birthday party.',
            'Anna doesn\'t need to invite friends to her birthday party.'
        ];

        const result = this.replaceUncommonParts(sentences, descriptiveSentence);
        console.log("🚀 ~ file: simple-test.component.ts:255 ~ SimpleTestComponent ~ transformExercise ~ result:", result)

        // // First, extract the common substring from the answer sentences
        // const blankSentence = this.getBlankSentence(answerSentences);
        // console.log("🚀 ~ file: simple-test.component.ts:247 ~ SimpleTestComponent ~ transformExercise ~ blankSentence:", blankSentence)

        // // Then, extract the differing substring from the descriptive sentence
        // const differingSubstring = this.getDifferingSubstring(descriptiveSentence, blankSentence);
        // console.log("🚀 ~ file: simple-test.component.ts:251 ~ SimpleTestComponent ~ transformExercise ~ differingSubstring:", differingSubstring)

        // // Finally, extract the possible answers by removing the common substring from the answer sentences
        // const possibleAnswers = this.getPossibleAnswers(answerSentences, blankSentence);
        // console.log("🚀 ~ file: simple-test.component.ts:255 ~ SimpleTestComponent ~ transformExercise ~ possibleAnswers:", possibleAnswers)

        // // Combine everything into the final transformed exercise string
        // const transformedExercise = `${blankSentence} (${differingSubstring})\n${possibleAnswers.join('\n')}`;

        // console.log("🚀 ~ file: simple-test.component.ts:260 ~ SimpleTestComponent ~ transformExercise ~ transformedExercise:", transformedExercise)
        // return transformedExercise;
    }
    // This function replaces the uncommon parts of an array of strings with a blank space,
    // and returns the modified sentence with all possible answers to fill the blanks.
    replaceUncommonParts(strings, descriptiveSentence) {
        if (strings.length === 0) {
            return '';
        }

        // Find the longest common prefix of all the strings
        let commonPrefix = strings[0];
        for (let i = 1; i < strings.length; i++) {
            const str = strings[i];
            let j = 0;
            while (j < commonPrefix.length && j < str.length && commonPrefix[j] === str[j]) {
                j++;
            }
            commonPrefix = commonPrefix.slice(0, j);
        }

        // Remove the common prefix from all the strings
        const strings2 = strings.map(str => str.slice(commonPrefix.length));

        // Find the longest common suffix of all the reversed strings
        const reversed = strings.map(str => str.split('').reverse().join(''));
        let commonSuffix = reversed[0];
        for (let i = 1; i < reversed.length; i++) {
            const str = reversed[i];
            let j = 0;
            while (j < commonSuffix.length && j < str.length && commonSuffix[j] === str[j]) {
                j++;
            }
            commonSuffix = commonSuffix.slice(0, j);
        }

        // Remove the common suffix from all the strings
        const strings3 = strings2.map(str => str.slice(0, str.length - commonSuffix.length));

        // Build the blank sentence with the common prefix and suffix
        const blankSentence = commonPrefix + '__________ (^)' + commonSuffix.split('').reverse().join('');

        // Find all possible answers by removing the uncommon parts from each string
        const allAnswers = [];
        strings3.forEach(str => {
            const itemToPush = str.replace(/\s+/g, ' ').trim();
            if (itemToPush !== '' && !allAnswers.includes(itemToPush)) {
                allAnswers.push(itemToPush);
            }
        });

        // Find the descriptive sentence without the common prefix and suffix
        const [result1, result2] = this.removeCommonParts(descriptiveSentence, blankSentence);
        const modifiedSentence = blankSentence.replace('^', result1);

        // Return the modified sentence with all possible answers
        return modifiedSentence + '---' + allAnswers.join('---');
    }

    // This function removes the longest common substring from two strings
    removeCommonParts(str1, str2) {
        let commonSubstring = '';
        for (let i = 0; i < str1.length; i++) {
            for (let j = 0; j < str2.length; j++) {
                let k = 0;
                while (str1[i + k] === str2[j + k] && i + k < str1.length && j + k < str2.length) {
                    k++;
                }
                if (k > commonSubstring.length) {
                    commonSubstring = str1.substring(i, i + k);
                }
            }
        }
        return [str1.replace(commonSubstring, ''), str2.replace(commonSubstring, '')];
    }




    ngOnDestroy() {
    }
}
