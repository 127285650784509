import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PictureFinderComponent } from 'src/app/shared/components/picture-finder/picture-finder.component';
import { HelpersService } from 'src/app/shared/services/helpers.service';
export interface Chunk {
  id: string;
  text: string;
  picture: string;
  audio: string;
  type: 'text' | 'dialogue' | 'questionTF' | 'questionMC' | 'answer';
}
@Component({
  selector: 'app-set-text-constructor',
  templateUrl: './set-text-constructor.component.html',
  styleUrls: ['./set-text-constructor.component.scss'],
})
export class SetTextConstructorComponent implements OnInit {
  chunks: Chunk[] = [];
  types = ['text', 'dialogue', 'questionTF', 'questionMC', 'answer'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helpers: HelpersService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SetTextConstructorComponent>
  ) {}

  ngOnInit() {
    if (this.data?.chunks) {
      this.chunks = this.data.chunks;
    } else {
      this.chunks.push(this.getEmptyChunk());
    }
  }
  openChoosePictureDialog(chunk: Chunk) {
    const dialogRef = this.dialog.open(PictureFinderComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      if (result) {
        chunk.picture = result.bigUrl;
      }
    });
  }
  getEmptyChunk(): Chunk {
    return {
      id: this.helpers.makeid(),
      text: null,
      picture: null,
      audio: null,
      type: null,
    };
  }
  addChunk() {
    this.chunks.push(this.getEmptyChunk());
  }
  removeChunk(chunk: Chunk) {
    if (confirm('Are you sure you want to delete this chunk?')) {
      this.chunks = this.chunks.filter((c) => c.id !== chunk.id);
    }
  }
  saveText() {
    this.dialogRef.close({ content: this.chunks, prop: this.data.prop });
  }
}
