<mat-dialog-content class="mat-typography">
  <app-plan-temp-group [groupToEdit]="group"></app-plan-temp-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="!userDetailsService.teacher"
    (click)="removeTempGroup()"
    mat-stroked-button
    color="warn"
  >
    Usuń
  </button>
  <div fxFlex></div>
  <button mat-button mat-dialog-close>Zamknij</button>
  <button
    mat-button
    color="primary"
    *ngIf="!editMode"
    (click)="addGroup()"
    cdkFocusInitial
  >
    Dodaj
  </button>
  <button
    mat-flat-button
    color="primary"
    *ngIf="editMode && !userDetailsService.teacher"
    (click)="addGroup(true)"
    cdkFocusInitial
  >
    Zapisz
  </button>
</mat-dialog-actions>
