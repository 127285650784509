<div
  fxFlex
  fxLayoutAlign="start center"
  fxLayout="column"
  style="width: 98vw; overflow: hidden"
  [ngStyle.gt-sm]="{ height: '95vh' }"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    style="overflow-y: auto; width: 100%; overflow-x: hidden"
  >
    <div style="width: 100%" fxLayoutAlign="space-between center">
      <div
        fxLayout="row"
        style="
          width: 100%;
          overflow-y: visible;
          overflow-x: auto;
          margin-top: 3px;
        "
        fxLayoutAlign="center center"
      >
        <div
          [ngStyle.gt-sm]="{ width: '99%' }"
          [ngStyle.lt-md]="{ width: '80%' }"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <app-user-avatar-small
            (addTaskClicked)="addTask()"
            [style.border]="
              worker?.name == selectedWorker?.name ? '3px solid #673ab7' : null
            "
            class="mb-1 pointer"
            (click)="this.workerSelected({ value: worker })"
            [name]="worker?.name"
            [avatar]="workersAvatarsService.getAvatarURL(worker?.name)"
            [selected]="worker?.name == selectedWorker?.name"
            style="width: 170px; border-radius: 10px"
            *ngFor="let worker of workers"
          ></app-user-avatar-small>
        </div>
      </div>
    </div>
    <!-- <button
            class="p-0"
            [ngClass]="{'mb-2': pinnedTasks.length > 0}"
            [style.width]=" (deviceSizeService.isHandset |async)? '80vw': '48vw'"
            (click)="addTask()"
            [disabled]="!selectedWorker"
            mat-flat-button
            color="primary"
        >
            <span style="font-weight: bold;">Dodaj zadanie dla {{selectedWorker?.name}}
            </span>
        </button> -->

    <!-- <div
      fxLayoutAlign="center center"
      style="width: 100%;"
      fxLayout="column"
      fxLayout.lt-md="row"
      fxLayoutGap.lt-md="10px"
    >
      <mat-form-field
        style="width: 70%; margin-bottom: -1.25em;"
        appearance="outline"
      >
        <mat-select
          (selectionChange)="workerSelected($event)"
          placeholder="Wybierz pracownika"
          [(value)]="selectedWorker"
        >
          <mat-option #select *ngFor="let worker of workers" [value]="worker"
            >{{worker.name}} | {{worker.role}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    
    </div> -->
  </div>
  <span
    *ngIf="
      pinnedTasks?.length > 0 &&
      (selectedWorker?.id == userDetailsService.getSimpleUserDetails().id ||
        userDetailsService.owner)
    "
    class="mat-small"
    >Przypięte zadania
  </span>
  <div
    *ngIf="
      pinnedTasks?.length > 0 &&
      (selectedWorker?.id == userDetailsService.getSimpleUserDetails().id ||
        userDetailsService.owner)
    "
    class="ml-2"
    style="max-height: 10vh; overflow-y: auto; padding: 4px"
  >
    <mat-chip-list>
      <mat-chip
        style="max-width: 45vw; max-height: 30px; text-overflow: ellips"
        selected
        color="accent"
        (click)="openTaskViewPanel(task)"
        *ngFor="let task of pinnedTasks"
      >
        <span
          style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
          >{{ task?.title }}</span
        >
      </mat-chip>
    </mat-chip-list>
  </div>
  <mat-tab-group fxFlex style="width: 100%" class="pl-2">
    <mat-tab label="Wszystko">
      <div class="mb-3 pr-3" fxFlex fxLayout="column" style="height: 62vh">
        <app-tasks-list
          [selectedWorker]="selectedWorker"
          [workers]="workers"
          [tasks]="myTasks"
        ></app-tasks-list>
      </div>
    </mat-tab>
    <!-- <mat-tab label="Harmonogram">
            <div
                class="mb-3 pr-3 mt-3"
                fxFlex
                fxLayout="column"
                style="height: 62vh;"
            >
                <app-workers-tasks
                    fxFlex
                    style="overflow-y: auto;"
                    [selectedWorker]="selectedWorker"
                    [workers]="workers"
                    [forTodayTasks]="forTodayTasks"
                    [platformTasks]="platformTasks"
                    [overdueTasks]="overdueTasks"
                    [forFutureTasks]="forFutureTasks"
                    [finishedTasks]="finishedTasks"
                ></app-workers-tasks>
            </div>
        </mat-tab>
        <mat-tab label="Rozmowy">
            <div
                class="mb-3 pr-3"
                fxFlex
                fxLayout="column"
                style="height: 62vh;"
            >
                <app-tasks-list
                    [selectedWorker]="selectedWorker"
                    [workers]="workers"
                    [tasks]="conversaionTasks"
                ></app-tasks-list>
            </div>
        </mat-tab> -->
    <mat-tab
      *ngIf="
        (userDetailsService.owner || userDetailsService.office) &&
        selectedWorker?.role == 'office'
      "
      label="Zaległości"
    >
      <div class="mb-3 pr-3" fxFlex fxLayout="column" style="height: 62vh">
        <app-tasks-list
          [selectedWorker]="selectedWorker"
          [workers]="workers"
          [tasks]="paymentsTasks"
        ></app-tasks-list>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="userDetailsService.owner || userDetailsService.office"
      label="Odrabianie"
    >
      <div class="mb-3 pr-3" fxFlex fxLayout="column" style="height: 62vh">
        <app-tasks-list
          [selectedWorker]="selectedWorker"
          [workers]="workers"
          [tasks]="this.rescheduleLessonsTasks"
        ></app-tasks-list>
      </div>
    </mat-tab>
  </mat-tab-group>
  <!-- <div
        class="pl-2"
        #tasksContainer
        style="overflow-y: auto; width: 98vw;"
        fxFlex
        fxLayout="row"
        fxHide.lt-md
        fxLayoutGap="20px"
    >
        <div
            class="mt-3"
            style="width: 32vw;"
            [style.width]="(deviceSizeService.isHandset |async)? '98vw': '32vw'"
            fxLayout="column"
        >
            <span style="font-size: 1.5em;">Wszystko</span>
            <div
                fxFlex
                style="overflow-y: auto;"
            >
                <app-tasks-list
                    [limit]="50"
                    [selectedWorker]="selectedWorker"
                    [workers]="workers"
                    [tasks]="myTasks"
                ></app-tasks-list>
            </div>
        </div>
        <div
            style="width: 32vw;"
            [style.width]="(deviceSizeService.isHandset |async)? '98vw': '32vw'"
            fxLayout="column"
        >
            <span style="font-size: 1.5em;">Harmonogram dla {{selectedWorker?.name}}
            </span>
            <app-workers-tasks
                fxFlex
                style="overflow-y: auto;"
                [selectedWorker]="selectedWorker"
                [workers]="workers"
                [forTodayTasks]="forTodayTasks"
                [platformTasks]="platformTasks"
                [overdueTasks]="overdueTasks"
                [forFutureTasks]="forFutureTasks"
                [finishedTasks]="finishedTasks"
            ></app-workers-tasks>
        </div>
        <div
            class="mt-3"
            style="width: 32vw;"
            [style.width]="(deviceSizeService.isHandset |async)? '98vw': '32vw'"
            fxLayout="column"
        >
            <span style="font-size: 1.5em;">Rozmowy</span>
            <div
                fxFlex
                style="overflow-y: auto;"
            >
                <app-tasks-list
                    [limit]="50"
                    [selectedWorker]="selectedWorker"
                    [workers]="workers"
                    [tasks]="conversaionTasks"
                ></app-tasks-list>
            </div>
        </div>
    </div> -->
</div>
