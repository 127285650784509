import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  url;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }
  sendEmail(customerData) {
    this.http
      .post(this.url + '/api/email/sendemail', customerData)
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: email.service.ts ~ line 32 ~ EmailService ~ sendEmail ~ response',
          response,
        );
      });
  }
  sendEmailWithHtml(data, forReal) {
    if (forReal) {
      data.forReal = forReal;
    }

    this.http
      .post(this.url + '/api/email/sendemailwithhtml', data)
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: email.service.ts ~ line 32 ~ EmailService ~ sendEmail ~ response',
          response,
        );
      });
  }
}
