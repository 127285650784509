import { BookingFormComponent } from './public-access/booking-form/booking-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { RegisterComponent } from './register/register.component';
import { RoleGuardService } from './shared/services/role-guard-service.service';
import { RoundsDividerComponent } from './profile/student/quiz/rounds-divider/rounds-divider.component';
import { SetsFreeComponent } from './database/sets-free/sets-free.component';
import { EventSelectorFrontComponent } from './public-access/booking-form/event-selector-front/event-selector-front.component';
import { SplashComponent } from './profile/student/quiz/splash/splash.component';
import { AddTaskComponent } from './shared/components/add-task/add-task.component';
import { SimpleTestComponent } from './shared/simple-test/simple-test.component';
import { ScheduleComponent } from './shared/components/schedule/schedule.component';
import { ManipulatorComponent } from './database/manipulator/manipulator.component';
import { WorkHoursComponent } from './profile/teacher/work-hours/work-hours.component';
import { UsersComponent } from './profile/owner/users/users.component';
import { ContactRequestsComponent } from './profile/office/contact-requests/contact-requests.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf2/pdf2.module').then((m) => m.Pdf2Module),
  },
  {
    path: 'make-video',
    loadChildren: () =>
      import('./video-maker/video-maker.module').then(
        (m) => m.VideoMakerModule,
      ),
  },
  {
    path: 'live',
    loadChildren: () =>
      import('./public-access/live/student/student.module').then(
        (m) => m.StudentModule,
      ),
  },

  { path: 'reset/:token', component: ResetPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'book', component: EventSelectorFrontComponent },
  { path: 'test', component: SimpleTestComponent },

  // { path: 'temp', component: VerbComponent },
  {
    path: 'signup/:city',
    loadChildren: () =>
      import('./public-access/signup-form/signup.module').then(
        (m) => m.SignupModule,
      ),
  },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      { path: 'manipulator', component: ManipulatorComponent },
      { path: 'contact-requests', component: ContactRequestsComponent },

      {
        path: 'list',
        component: WorkHoursComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'office'],
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'finder',
        loadChildren: () =>
          import('./database/finder/finder.module').then((m) => m.FinderModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'lessons',
        loadChildren: () =>
          import('./lessons/lessons.module').then((m) => m.LessonsModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'tasks',
        component: AddTaskComponent,
      },
      {
        path: 'sentences',
        loadChildren: () =>
          import('./database/sentences/sentences.module').then(
            (m) => m.SentencesModule,
          ),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'grammar',
        loadChildren: () =>
          import('./database/grammar/grammar.module').then(
            (m) => m.GrammarModule,
          ),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'books',
        loadChildren: () =>
          import('./database/books/books.module').then((m) => m.BooksModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia', 'admin', 'office'],
        },
      },

      {
        path: 'pictures',
        loadChildren: () =>
          import('./database/pictures/pictures.module').then(
            (m) => m.PicturesModule,
          ),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'sets',
        loadChildren: () =>
          import('./database/sets/sets.module').then((m) => m.SetsModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'freesets',
        loadChildren: () =>
          import('./database/sets-free/sets-free.module').then(
            (m) => m.SetsFreeModule,
          ),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'owner',
        loadChildren: () =>
          import('./profile/owner/owner.module').then((m) => m.OwnerModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia'],
        },
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./customers/customers.module').then((m) => m.CustomersModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'courses',
        loadChildren: () =>
          import('./courses/courses.module').then((m) => m.CoursesModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
      {
        path: 'schedule',
        component: ScheduleComponent,
      },
      {
        path: 'live',
        loadChildren: () =>
          import('./live/live.module').then((m) => m.LiveModule),
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['owner', 'teacher', 'agusia', 'admin', 'office'],
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    // RouterModule.forRoot(routes, {
    //   // preloadingStrategy: PreloadAllModules
    // })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
