import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VersionService } from './version.service';
@Injectable({
  providedIn: 'root',
})
export class SignupFormService {
  students = [];
  public studentsUpdated = new Subject<any[]>();
  public studentUpdated = new Subject();
  private foundstudentsByIdUpdated = new Subject();
  // url = 'https://test.lingking.com.pl:3001'
  //  url = 'https://backend-int.linget.it'
  url;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  saveNewStudent(studentData, internal?) {
    this.http
      .post(this.url + '/api/signup/save', studentData)
      .subscribe((response) => {
        if (!internal) {
          window.top.location = <any>'https://lingking.pl/wszystko-w-porzadku';
        } else {
          this.getStudentList();
        }
      });
  }

  getStudentsById(studentsIds: Array<string>, dbId) {
    this.http
      .post(this.url + '/api/signup/get-students-by-id/', {
        studentsIds: studentsIds,
        dbId: dbId,
      })
      .subscribe((response) => {
        this.foundstudentsByIdUpdated.next(response);
      });
  }
  getStudentList() {
    this.http
      .get(this.url + '/api/signup/getList', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let studentsArray = Object.keys(response).map((i) => response[i]);

        studentsArray = studentsArray[0].map((incomingStudent) => {
          return {
            id: incomingStudent._id,
            customerFirstName: incomingStudent.data.customerFirstName,
            customerLastName: incomingStudent.data.customerLastName,
            customerPhone: incomingStudent.data.customerPhone,
            customerEmail: incomingStudent.data.customerEmail,
            location: incomingStudent.data.location,
            internal: incomingStudent.data.internal,
            students: incomingStudent.data.students,
          };
        });
        this.students = studentsArray;

        this.studentsUpdated.next(this.students);
      });
  }
  // filterStudents(text) {
  //
  //   const lowerText = text.toLowerCase()
  //   const filteredStudents = this.students.filter(e => e.childFirstName.toLowerCase().includes(lowerText))

  //   this.studentsUpdated.next(filteredStudents)

  // }

  removeCustomer(id) {
    this.http
      .delete(this.url + '/api/signup/delete-customer/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.students = this.students.filter((e) => e.id !== id);

        this.studentsUpdated.next(this.students);
      });
  }

  updateCustomer(customer) {
    this.http
      .put(
        this.url + '/api/signup/update-customer/' + customer.customerId,
        customer,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.id === customer.customerId,
        );
        const adultId = this.students[currentId].students.findIndex(
          (e) => e.id === customer.customerId,
        );
        const singleCustomer = this.students[currentId];
        singleCustomer.customerFirstName = customer.customerFirstName;
        singleCustomer.customerLastName = customer.customerLastName;
        singleCustomer.customerPhone = customer.customerPhone;
        singleCustomer.customerEmail = customer.customerEmail;
        singleCustomer.location = customer.location;

        // this.studentsUpdated.next(this.students);
        this.studentUpdated.next({
          type: 'customer',
          value: customer,
        });
      });
  }
  updateChild(child) {
    this.http
      .put(this.url + '/api/signup/update-child/' + child.customerId, child, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.id === child.customerId,
        );
        const childId = this.students[currentId].students.findIndex(
          (e) => e.id === child.childId,
        );

        const singleChild = this.students[currentId].students[childId];
        if (singleChild) {
          singleChild.childFirstName = child.childFirstName;
          singleChild.childLastName = child.childLastName;
          singleChild.childLevel = child.childLevel;
          singleChild.childGrade = child.childGrade;
          singleChild.childPreviousClasses = child.childPreviousClasses;
          singleChild.additionalData = child.additionalData;
          singleChild.avalibility = child.avalibility;
          singleChild.status = child.status;
          singleChild.weeklyLessons = child.weeklyLessons;
          singleChild.weeklyLessons = child.weeklyLessons;
          singleChild.lastYearPrice = child.lastYearPrice;
          singleChild.thisYearPrice = child.thisYearPrice;
          singleChild.contractPrice = child.contractPrice;
          singleChild.priceAddition = child.priceAddition;
          // this.studentsUpdated.next(this.students);
        }
        // this.getStudentList();
        this.studentUpdated.next({
          type: 'child',
          value: child,
        });
      });
  }

  updateAdult(adult) {
    this.http
      .put(this.url + '/api/signup/update-adult/' + adult.customerId, adult, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.id === adult.customerId,
        );
        const adultId = this.students[currentId].students.findIndex(
          (e) => e.id === adult.adultId,
        );
        let singleAdult = this.students[currentId].students[adultId];
        if (singleAdult) {
          singleAdult.adultFirstName = adult.adultFirstName;
          singleAdult.adultLastName = adult.adultLastName;
          singleAdult.adultPhone = adult.adultPhone;
          singleAdult.adultEmail = adult.adultEmail;
          singleAdult.status = adult.status;
          singleAdult.weeklyLessons = adult.weeklyLessons;
          singleAdult.lastYearPrice = adult.lastYearPrice;
          singleAdult.thisYearPrice = adult.thisYearPrice;
          singleAdult.contractPrice = adult.contractPrice;
          singleAdult.priceAddition = adult.priceAddition;
          (singleAdult.adultAdditionalData = adult.adultAdditionalData),
            (singleAdult.avalibility = adult.avalibility);

          // this.studentsUpdated.next(this.students);
          // this.getStudentList();
          this.studentUpdated.next({
            type: 'adult',
            value: adult,
          });
        }
      });
  }

  studentsUpdatedListener() {
    return this.studentsUpdated.asObservable();
  }
  foundstudentsByIdListener() {
    return this.foundstudentsByIdUpdated.asObservable();
  }
}
