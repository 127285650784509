<div style="height: calc(100vh - 64px)" fxLayout="column" class="m-3">
  <mat-card fxLayout="row wrap" style="width: 100%" class="mb-3">
    <div fxLayout="row wrap" class=""fxLayoutAlign="start center">
      <button mat-icon-button color="primary" *ngIf="editMode" (click)="setEditMode(true)"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button  *ngIf="!editMode" (click)="setEditMode(false)" ><mat-icon>edit</mat-icon></button>
      <mat-form-field
        *ngIf="editMode"
        style="margin-bottom: -1.25em"
        appearance="outline"
      >
        <mat-label>Lektor</mat-label>
        <mat-select [(value)]="selectedTeacher">
          <mat-option [value]="teacher" *ngFor="let teacher of teachers">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <img height="30px" [src]="teacher.avatar" alt="" />
              <span>
                {{ teacher.name }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ml-2" style="margin-bottom: -1.25em" appearance="outline">
        <input
          #input
          matInput
          (input)="filterGroupsWithStudents(input.value)"
        />
      </mat-form-field>
    
      <mat-button-toggle-group
        multiple
        class="ml-2"
        (change)="setVisibleDays($event)"
      >
        <mat-button-toggle [value]="1">Poniedziałek</mat-button-toggle>
        <mat-button-toggle [value]="2">Wtorek</mat-button-toggle>
        <mat-button-toggle [value]="3">Środa</mat-button-toggle>
        <mat-button-toggle [value]="4">Czwartek</mat-button-toggle>
        <mat-button-toggle [value]="5">Piątek</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group
        multiple
        class="ml-2"
        (change)="filterEventsForSelectedTeachers($event)"
      >
        <mat-button-toggle
          multiple
          *ngFor="let teacher of activeTeachers"
          [value]="teacher.teacherId"
        >
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
            <img style="width: 30px; height: 30px" [src]="teacher.avatar" />
            <span>
              {{ teacher.name }}
            </span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <button
      class="mt-2"
      matTooltip="Pokaż wszystko"
      mat-icon-button
      (click)="showAllEvents()"
      [disabled]="!filteredMode"
    >
      <mat-icon class="mat-18">apps</mat-icon>
    </button>
  </mat-card>
  <div style="height: 50vh">
    <ng-template
      #weekViewHourSegmentTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
    >
      <div
        #segmentElement
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        (mousedown)="startDragToCreate(segment, $event, segmentElement)"
      >
        <div class="cal-time" *ngIf="isTimeLabel">
          {{ segment.date | calendarDate: "weekViewHour":locale }}
        </div>
      </div>
    </ng-template>
    <mwl-calendar-week-view
      (eventClicked)="eventClicked($event)"
      [hourSegments]="2"
      [eventSnapSize]="15"
      [dayStartHour]="8"
      [dayEndHour]="21"
      [viewDate]="viewDate"
      [events]="events"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      [excludeDays]="excludeDays"
      [refresh]="refresh2"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
  </div>
</div>
