import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ImagesConverterService } from './images-converter.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ReadTextService } from './read-text.service';
import { CustomersService } from './customers.service';
import { InternetConnectionService } from './internet-connection.service';
import { VersionService } from './version.service';
@Injectable({
  providedIn: 'root',
})
export class GetSetContentService {
  url;
  sub;
  sub2;
  sub3;
  setContent = new Subject();
  setContent2 = new Subject();
  setItems = new Subject();
  currentSet;
  downloadAllowed = false;
  objectIndex = 0;
  objectIndex2 = 0;
  objectIndex3 = 0;
  internetState = null;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  audioToGetList: any[];
  constructor(
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private internetConnectionService: InternetConnectionService,
    private dbService: NgxIndexedDBService,
    private imageConverter: ImagesConverterService,
    private readTextService: ReadTextService,
    private customersService: CustomersService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  getSetContent(id, studentId?, onlyText?) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    this.sub = this.imageConverter
      .AddingImgFinished()
      .subscribe((added: any) => {
        setTimeout(() => {
          let itemToUpdate = this.currentSet.items.filter(
            (item) => item.wordId + item.meaningId === added.index,
          )[0];
          if (itemToUpdate) {
            itemToUpdate.item[added.type] = added.img;
          }
          this.objectIndex++;
          if (!onlyText) {
            this.checkImages();
          }
        }, 100);
      });
    this.sub3 = this.internetConnectionService
      .connectedToServerStateChanged()
      .subscribe((state) => {
        this.internetState = state;
      });
    this.sub2 = this.readTextService
      .audioAddedIndicator()
      .subscribe((added: any) => {
        if (this.internetState === 'connected') {
          if (added.next === true && this.downloadAllowed) {
            this.objectIndex3++;
            const value = this.audioToGetList[this.objectIndex3]?.value;
            if (value) {
              if (!onlyText) {
                this.checkAudio2(value);
              }
            } else {
              this.objectIndex3 = 0;
              this.audioToGetList = [];
              this.sub2.unsubscribe();

              // alert('audio downloaded!')
            }
          } else {
            this.objectIndex3 = 0;
            this.audioToGetList = [];

            this.downloadAllowed = false;
            this.currentSet.items.forEach((item) => {
              if (
                item.item.tags.some((tag) => tag.tag.includes('lang:spanish'))
              ) {
                item.item.wordAudio = item.item.wordAudioES;
                item.item.sentenceAudio = item.item.sentenceAudioES;
                item.item.blankAudio = item.item.blankAudioES;
              }
            });
            this.setContent.next(this.currentSet);
            this.sub2.unsubscribe();
          }
        }
      });
    let ass = 0;
    const dataToSend = id;
    this.http
      .post<{ sets: any; missingItems: any }>(this.url + '/api/sets/getset', {
        id: dataToSend,
      })
      .pipe(
        map((sets) => {
          return sets.sets.map((set) =>
            //stats undefined here
            {
              return {
                id: set._id,
                studentId: studentId,
                name: set.data.name,
                added: set.data.added,
                items: set.data.items,
                children: set.data?.children,
                owner: set.data?.owner,
                lastModified: set.data?.lastModified,
                type: set.data.type,
                simpleWords: set.data.simpleWords,
                textChunks: set.data?.textChunks,
                dialogue: set.data?.dialogue,

                starredItems: set.data.starredItems,
                params: set.data.params,
                allGoodAnswers: set.data?.allGoodAnswers,
                grammarTextSetType: set.data?.grammarTextSetType,
                frontImage: set.data?.frontImage,
                illustrationImage: set.data?.illustrationImage,
                stats: set.data.stats,
                tags: set.data.tags,
                paths: set.data.paths,
                missingWords: set.data.missingWords,
                missingItems: sets.missingItems,
              };
            },
          );
        }),
      )
      .subscribe((set) => {
        let _set = set[0];

        this.currentSet = _set;
        this.currentSet.items.forEach((item) => {
          if (
            item?.item?.tags?.some((tag) => tag?.tag?.includes('lang:spanish'))
          ) {
            item.item.wordAudio = item.item.wordAudioES;
            item.item.sentenceAudio = item.item.sentenceAudioES;
            item.item.blankAudio = item.item.blankAudioES;
          }
        });
        this.setContent.next(this.currentSet);

        if (!onlyText) {
          this.generateList();
          this.checkImages();
        }
        // this.checkAudio('wordAudio')
      });
  }
  checkImages() {
    const item = this.currentSet.items[this.objectIndex];

    if (item) {
      if (!this.currentSet.children && this.currentSet.type !== 'free') {
        if (!this.isHandset$) {
          this.dbService
            .getByIndex('images', 'link', item.item.smallPicture)
            .subscribe((image) => {
              if (!image) {
                this.imageConverter.sendImageToLocalDb(
                  item.item.smallPicture,
                  item.wordId + item.meaningId,
                  false,
                  'smallPicture',
                );
              } else {
                if (image?.value) {
                  item.item.smallPicture = image.value;
                  this.imageConverter.nextItem();
                }
              }
            });
        }
        this.dbService
          .getByIndex('images', 'link', item.item.iconPicture)
          .subscribe((image) => {
            if (!image) {
              this.imageConverter.sendImageToLocalDb(
                item.item.iconPicture,
                item.wordId + item.meaningId,
                false,
                'iconPicture',
              );
            } else {
              if (image?.value) {
                item.item.iconPicture = image.value;
                this.imageConverter.nextItem();
              }
            }
          });
      }
    }
    if (this.currentSet.children && this.currentSet.type !== 'free') {
      const item = this.currentSet.items[this.objectIndex];
      if (item) {
        if (!this.isHandset$) {
          this.dbService
            .getByIndex('images', 'link', item.item.childrenSmallPicture)
            .subscribe((image) => {
              if (!image) {
                this.imageConverter.sendImageToLocalDb(
                  item.item.childrenSmallPicture,
                  item.wordId + item.meaningId,
                  true,
                  'childrenSmallPicture',
                );
              } else {
                if (image?.value) {
                  item.item.childrenSmallPicture = image.value;
                  this.imageConverter.nextItem();
                }
              }
            });
        }
        this.dbService
          .getByIndex('images', 'link', item.item.childrenIconPicture)
          .subscribe((image) => {
            if (!image) {
              this.imageConverter.sendImageToLocalDb(
                item.item.childrenIconPicture,
                item.wordId + item.meaningId,
                true,
                'childrenIconPicture',
              );
            } else {
              if (image?.value) {
                item.item.childrenIconPicture = image.value;
                this.imageConverter.nextItem();
              }
            }
          });
      }
    }
    if (this.currentSet.type === 'free') {
      const item = this.currentSet.items[this.objectIndex];
      if (item) {
        if (!this.isHandset$) {
          this.dbService
            .getByIndex('images', 'link', item.smallPicture)
            .subscribe((image) => {
              if (!image) {
                this.imageConverter.sendImageToLocalDb(
                  item.smallPicture,
                  item.id,
                  true,
                  'smallPicture',
                );
              } else {
                if (image?.value) {
                  item.smallPicture = image.value;
                  this.imageConverter.nextItem();
                }
              }
            });
        }
        this.dbService
          .getByIndex('images', 'link', item.iconPicture)
          .subscribe((image) => {
            if (!image) {
              this.imageConverter.sendImageToLocalDb(
                item.iconPicture,
                item.id,
                true,
                'iconPicture',
              );
            } else {
              if (image?.value) {
                item.iconPicture = image.value;
                this.imageConverter.nextItem();
              }
            }
          });
      }
    }

    if (this.objectIndex === this.currentSet.items.length) {
      this.currentSet.items.forEach((item) => {
        if (item.item.tags.some((tag) => tag.tag.includes('lang:spanish'))) {
          item.item.wordAudio = item.item.wordAudioES;
          item.item.sentenceAudio = item.item.sentenceAudioES;
          item.item.blankAudio = item.item.blankAudioES;
        }
      });
      this.setContent.next(this.currentSet);
    }
  }
  generateList() {
    this.audioToGetList = [];
    this.objectIndex3 = 0;
    this.currentSet.items.forEach((item) => {
      if (item.item) {
        this.audioToGetList.push({ value: item.item.wordAudio });
        this.audioToGetList.push({ value: item.item.wordAudioES });
        this.audioToGetList.push({ value: item.item.sentenceAudio });
        this.audioToGetList.push({ value: item.item.sentenceAudioES });
        this.audioToGetList.push({ value: item.item.wordAudioGB });
        this.audioToGetList.push({ value: item.item.sentenceAudioGB });
        this.audioToGetList.push({ value: item.item.blankAudio });
        this.audioToGetList.push({ value: item.item.blankAudioES });
        this.audioToGetList.push({ value: item.item.blankAudioGB });
        this.audioToGetList.push({ value: item.item.polishAudio });
        if (item.item.disassembledChildrenSentence.length > 0) {
          this.audioToGetList.push({ value: item.item.childrenSentenceAudio });
          this.audioToGetList.push({
            value: item.item.childrenSentenceAudioGB,
          });
          this.audioToGetList.push({ value: item.item.childrenBlankAudio });
          this.audioToGetList.push({ value: item.item.childrenBlankAudioGB });
        }
      }
    });
    this.audioToGetList = this.audioToGetList.filter((item) => item.value);

    this.downloadAllowed = true;
    this.checkAudio2(this.audioToGetList[0].value);
  }

  checkAudio2(value) {
    this.readTextService.getAudioData(value, true, 'wordAudioGB', true);
  }

  //   checkAudio(property){

  //   const item = this.currentSet.items[this.objectIndex2]
  //   const checkItem = this.currentSet.items[this.objectIndex2]?.item.sentenceAudio
  //   const length = this.currentSet.items.length
  //
  //
  //
  // const next = item?.item?.disassembledChildrenSentence.length>0
  //
  //   if(checkItem){
  //
  //    switch (property) {
  //      case 'wordAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.wordAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.wordAudio, true, 'wordAudioGB')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.wordAudio, true, 'wordAudioGB', true)
  //         }
  //       },
  //       err =>{
  //
  //
  //         this.readTextService.getAudioData(item.item.wordAudio, true, 'wordAudioGB', true)

  //       }
  //       );
  //      case 'wordAudioGB':
  //       this.dbService.getByIndex('audio', 'link', item.item.wordAudioGB).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.wordAudioGB, true, 'sentenceAudio')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.wordAudioGB, true, 'sentenceAudio', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.wordAudioGB, true, 'sentenceAudio', true)

  //       });
  //        break;
  //      case 'sentenceAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.sentenceAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.sentenceAudio, true, 'sentenceAudioGB')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.sentenceAudio, true, 'sentenceAudioGB', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.sentenceAudio, true, 'sentenceAudioGB', true)

  //       });
  //      case 'sentenceAudioGB':
  //       this.dbService.getByIndex('audio', 'link', item.item.sentenceAudioGB).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.sentenceAudioGB, true, 'blankAudio')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.sentenceAudioGB, true, 'blankAudio', true)
  //         }
  //       },
  //       err =>{
  //

  //       });
  //        break;
  //      case 'blankAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.blankAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.blankAudio, true, 'blankAudioGB')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.blankAudio, true, 'blankAudioGB', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.blankAudio, true, 'blankAudioGB', true)

  //       });
  //      case 'blankAudioGB':
  //       this.dbService.getByIndex('audio', 'link', item.item.blankAudioGB).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.blankAudioGB, true, 'polishAudio')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.blankAudioGB, true, 'polishAudio', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.blankAudioGB, true, 'polishAudio', true)

  //       });
  //        break;
  //      case 'polishAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.polishAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.polishAudio, next, 'childrenSentenceAudio')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.polishAudio, next, 'childrenSentenceAudio', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.polishAudio, next, 'childrenSentenceAudio', true)

  //       });
  //        break;
  //      case 'childrenSentenceAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.childrenSentenceAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.childrenSentenceAudio, true, 'childrenSentenceAudioGB')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.childrenSentenceAudio, true, 'childrenSentenceAudioGB', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.childrenSentenceAudio, true, 'childrenSentenceAudioGB', true)

  //       });
  //       this.dbService.getByIndex('audio', 'link', item.item.childrenSentenceAudioGB).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.childrenSentenceAudioGB, true, 'childrenBlankAudio')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.childrenSentenceAudioGB, true, 'childrenBlankAudio', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.childrenSentenceAudioGB, true, 'childrenBlankAudio', true)

  //       });
  //        break;
  //      case 'childrenBlankAudio':
  //       this.dbService.getByIndex('audio', 'link', item.item.childrenBlankAudio).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.childrenBlankAudio, true, 'childrenBlankAudioGB')
  //         }
  //         else{
  //           this.readTextService.getAudioData(item.item.childrenBlankAudio, true, 'childrenBlankAudioGB', true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.childrenBlankAudio, true, 'childrenBlankAudioGB', true)

  //       });
  //        break;
  //      case 'childrenBlankAudioGB':
  //       this.dbService.getByIndex('audio', 'link', item.item.childrenBlankAudioGB).subscribe((audioFile) => {
  //         if(!audioFile){
  //         this.readTextService.getAudioData(item.item.childrenBlankAudioGB, false, null)
  //         }
  //            else{
  //           this.readTextService.getAudioData(item.item.childrenBlankAudioGB, false, null, true)
  //         }
  //       },
  //       err =>{
  //
  //         this.readTextService.getAudioData(item.item.childrenBlankAudioGB, false, null, true)

  //       });
  //        break;

  //      default:
  //        break;
  //    }

  // if(item.item.disassebbledChidrenSentence?.length>0){

  // }

  //   }
  // else{
  //
  //   this.objectIndex2++
  //   this.checkAudio('wordAudio')

  // }

  //   if(this.objectIndex2 === (this.currentSet.items.length)){
  //
  //     this.objectIndex2 = 0
  //     alert('audio downloaded!')
  //     // this.setContent.next(this.currentSet)
  //   }

  // }

  getEmptySetItems(set) {
    if (set?.items) {
      set.items.forEach((item) => {
        this.http
          .post<{ words: any }>(this.url + '/api/dictionary/findwordbyid/', {
            id: item.wordId,
          })
          .pipe(
            map((words) => {
              return words.words.map((word) => {
                return {
                  id: word._id,
                  meanings: word.data.meanings,
                };
              });
            }),
          )
          .subscribe((word) => {
            let _word = word[0];
            _word.meanings.forEach((meaning) => {
              if (meaning.id === item.meaningId) {
                item.item = meaning;
                if (
                  item.item.tags.some((tag) => tag.tag.includes('lang:spanish'))
                ) {
                  item.item.wordAudio = item.item.wordAudioES;
                  item.item.sentenceAudio = item.item.sentenceAudioES;
                  item.item.blankAudio = item.item.blankAudioES;
                }
              }
            });
          });
      });
      this.setItems.next(set);
    }
  }

  updateSetContent(set) {
    set.items.forEach((item) => {
      if (item.item.tags.some((tag) => tag.tag.includes('lang:spanish'))) {
        item.item.wordAudio = item.item.wordAudioES;
        item.item.sentenceAudio = item.item.sentenceAudioES;
        item.item.blankAudio = item.item.blankAudioES;
      }
    });
    this.setContent.next(set);
  }
  setItemsListener() {
    return this.setItems.asObservable();
  }
  setContentListener() {
    return this.setContent.asObservable();
  }
  setContent2Listener() {
    //complete set
    return this.setContent2.asObservable();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub3.unsubscribe();
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }
}
