<div style="width: 100%; height: 100%; display: flex; flex-direction: column">
  <div
    class="w-100"
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #673ab7;
    "
  >
    <h2>Super {{ data?.prop }} constructor {{ data?.name }}</h2>
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex: 1;
      gap: 1rem;
      overflow-y: auto;
    "
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
      "
      *ngFor="let chunk of chunks"
    >
      <textarea
        [(ngModel)]="chunk.text"
        class="mr-3"
        style="flex: 1; border: 2px solid #673ab7; border-radius: 5px"
        rows="3"
        matInput
      ></textarea>
      <select
        [(ngModel)]="chunk.type"
        class="mr-3"
        style="width: 100px; border: 2px solid #673ab7; border-radius: 5px"
      >
        <option *ngFor="let type of types" [value]="type">{{ type }}</option>
      </select>
      <div
        class="mr-2"
        style="
          width: 56px;
          height: 56px;
          border: 2px solid #673ab7;
          border-radius: 5px;
        "
      >
        <img
          *ngIf="chunk.picture"
          [src]="chunk.picture"
          style="width: 50px; height: 50px; object-fit: cover"
        />
      </div>
      <button
        (click)="openChoosePictureDialog(chunk)"
        mat-icon-button
        color="primary"
      >
        <mat-icon>add_photo_alternate</mat-icon>
      </button>
      <button (click)="removeChunk(chunk)" mat-icon-button color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="w-100"
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    "
  >
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="w-100"
      (click)="addChunk()"
    >
      Add chunk
    </button>
    <button
      (click)="saveText()"
      type="button"
      mat-flat-button
      color="primary"
      class="w-100"
    >
      Done
    </button>
  </div>
</div>
