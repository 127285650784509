import { InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthenticationService } from './shared/services/authentication.service';
import { SetTagsService } from './shared/services/set-tags.service';
import { SetsService } from './shared/services/sets.service';
import { LiveserviceService } from './shared/services/liveservice.service';
import { GetSetContentService } from './shared/services/get-set-content.service';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData, DatePipe } from '@angular/common';
registerLocaleData(localePl);
import { HttpClientModule } from '@angular/common/http';
import { UsersServiceService } from './shared/services/users-service.service';
import { PictureService } from './shared/services/pictures.service';
import { SignupFormService } from './shared/services/signup-form.service';
import { GrammarRulesService } from './shared/services/grammar-rules.service';
import { AddPictureComponent } from './database/pictures/add-picture/add-picture.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupModule } from './public-access/signup-form/signup.module';
import { CommonModule } from '@angular/common';
import { RoleGuardService } from './shared/services/role-guard-service.service';

import {
  MatDialogRef,
  MatDialogModule,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PdfComponent } from './pdf/pdf.component';
import { CreateComponent } from './pdf/create/create.component';

import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

// import { RaygunErrorHandler } from './app.raygun.setup';

import { HorizontalRaceComponent } from './live/boardgame/horizontal-race/horizontal-race.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventSelectorFrontComponent } from './public-access/booking-form/event-selector-front/event-selector-front.component';
import { SendEmailComponent } from './groups/send-email/send-email.component';
// import { RaygunErrorHandler } from './app.raygun.setup';
import { HelpComponent } from './help/help.component';

import { NgDragDropModule } from 'ng-drag-drop';
import { ManipulatorComponent } from './database/manipulator/manipulator.component';

declare global {
  interface Window {
    WonderPush?: any;
  }
}

const dbConfig: DBConfig = {
  name: 'LingkingOfflineDb',
  version: Date.now(),
  objectStoresMeta: [
    {
      store: 'images',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'link', keypath: 'link', options: { unique: false } },
        { name: 'value', keypath: 'value', options: { unique: false } },
      ],
    },
    {
      store: 'audio',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'link', keypath: 'link', options: { unique: false } },
        { name: 'value', keypath: 'value', options: { unique: false } },
      ],
    },
    {
      store: 'other',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'value', keypath: 'value', options: { unique: false } },
      ],
    },
  ],
};
@NgModule({
  declarations: [
    AppComponent,
    PdfComponent,
    CreateComponent,
    EventSelectorFrontComponent,
    SendEmailComponent,
    HelpComponent,

    ManipulatorComponent,
  ],
  imports: [
    NgDragDropModule.forRoot(),

    // ApmModule,
    NgxIndexedDBModule.forRoot(dbConfig),

    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    MatDialogModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    DatePipe,
    HorizontalRaceComponent,
    HttpClientModule,
    LiveserviceService,
    UsersServiceService,
    AuthenticationService,
    PictureService,
    SignupFormService,
    HttpClientModule,
    AddPictureComponent,
    GrammarRulesService,
    SetTagsService,
    SetsService,
    SignupModule,
    GetSetContentService,
    RoleGuardService,

    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: MAT_BOTTOM_SHEET_DATA,
      useValue: {},
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    //your locale

    // {
    //   provide: ErrorHandler,
    //   useClass: RaygunErrorHandler
    // }
  ],
  bootstrap: [AppComponent],
  exports: [
    // BookingFormComponent,
    // AddEventTimeDialogComponent,
  ],
})
export class AppModule {}
