import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UsersServiceService } from '../../services/users-service.service';
import { AddTaskDialogComponent } from './add-task-dialog/add-task-dialog.component';
import { LOCALE_ID, Inject } from '@angular/core';
import { YearlyPlanService } from '../../services/yearly-plan.service';
import { HelpersService } from '../../services/helpers.service';
import { DeviceSizeService } from '../../services/device-size.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckUsersTasksService } from '../../services/check-users-tasks.service';
import { RealTimeCommunicationService } from '../../services/real-time-communication.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TaskViewComponent } from './task-view/task-view.component';
import { worker } from 'cluster';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkersAvatarsService } from '../../services/workers-avatars.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css'],
})
export class AddTaskComponent implements OnInit {
  workers = [];
  conversaionTasks = [];
  selectedWorker: any = null;
  workerMode = false;
  anyPanelOpened = false;
  workersSub: any;
  refreshScheduled = false;
  handsetSub: any;
  authorMode = false;
  myTasks = [];
  pinnedTasks = [];
  commentToAdd: any;
  taskUpdatedSub: any;
  previouslySelectedWorker: any = null;
  tasksChangesSub: any;
  showAllTasks: any;
  messagesForWorkersSub: any;
  params;
  overdueTasks = [];
  forTodayTasks = [];
  forFutureTasks = [];
  finishedTasks = [];
  platformTasks = [];
  rescheduleLessonsTasks = [];
  number = 0;
  taskPanelOpened: any;
  paymentsTasks: any;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public usersService: UsersServiceService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private helpers: HelpersService,
    public userDetailsService: UserDetailsService,
    public deviceSizeService: DeviceSizeService,
    private yearlyPlanService: YearlyPlanService,
    private spinner: NgxSpinnerService,
    private checkUsersTasksService: CheckUsersTasksService,
    private realTimeCommunicationService: RealTimeCommunicationService,
    private router: Router,
    public workersAvatarsService: WorkersAvatarsService,
  ) {}

  ngOnInit(): void {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        console.log('visible');
        this.refreshTasks();
      } else {
        console.log('hidden');
      }
    });

    this.messagesForWorkersSub = this.realTimeCommunicationService
      .messagesForWorkersListener()
      .subscribe((message: any) => {
        if (message.removedTask) {
          let taskOwner = this.workers.find((worker) =>
            worker.userData.tasks.some(
              (task) => task.id == message.removedTask.id,
            ),
          );
          taskOwner.userData.tasks = taskOwner.userData.tasks.filter(
            (task) => task.id !== message.removedTask.id,
          );
        }
        if (message.updatedTask) {
          let taskOwner = this.workers.find(
            (worker) =>
              worker.userData &&
              worker.userData.tasks &&
              worker.userData.tasks.some(
                (task) => task.id == message.updatedTask.id,
              ),
          );
          if (taskOwner) {
            let taskNumber = taskOwner.userData.tasks.findIndex(
              (task) => task.id == message.updatedTask.id,
            );
            if (taskNumber >= 0) {
              taskOwner.userData.tasks[taskNumber] = message.updatedTask;
            }
          }
        }
        if (message.addedTask) {
          let taskOwner = this.workers.find(
            (worker) => worker.id == message.taskOwnerId,
          );
          if (
            !taskOwner.userData.tasks.some(
              (task) => task.id === message.addedTask.id,
            )
          ) {
            taskOwner.userData.tasks.push(message.addedTask);
          }
        }

        this.doTasks();
        this.createMyTasks();
      });
    this.tasksChangesSub = this.checkUsersTasksService
      .tasksUpdatedListener()
      .subscribe((tasksChanged: any) => {
        if (tasksChanged.message == 'tasksIncreased') {
          this.refreshScheduled = true;
          if (!this.anyPanelOpened) {
            this.refreshTasks();
          }
        }
      });
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      //
    }, 5000);
    this.taskUpdatedSub = this.usersService
      .taskUpdatedListener()
      .subscribe((taskUpdated) => {
        this.doTasks();
      });
    this.workersSub = this.usersService
      .workersUpdatedListener()
      .subscribe((workers) => {
        if (this.userDetailsService.getSimpleUserDetails()?.role === 'owner') {
          this.workerMode = false;
        }

        this.workers = workers;
        this.workers = this.workers.filter(
          (worker) =>
            worker.id !== '60214180a3210d4932017771' &&
            worker.id !== '60ae8f6687f8d107ddb63340',
        );
        this.workers.forEach((worker) => {
          const worker2 = this.workersAvatarsService.workersWithAvatars.find(
            (worker3) => worker3.id === worker.id,
          );
          worker.priority = worker2?.priority;
        });
        this.workers = this.workers.filter((worker) => worker.priority > 0);
        this.workers = this.helpers.sortArrayByProperty(
          this.workers,
          'priority',
          'desc',
        );
        console.log(
          '🚀 ~ file: add-task.component.ts ~ line 19 ~ AddTaskComponent ~ .subscribe ~  this.workers',
          this.workers,
        );

        const selectedWorker = this.selectedWorker
          ? this.selectedWorker
          : this.userDetailsService.getUserDetails();
        this.workerSelected({
          value: selectedWorker,
        });

        this.doTasks();
        this.createMyTasks();
        this.refreshScheduled = false;

        this.spinner.hide();
      });
    this.usersService.getWorkersList();
  }
  createMyTasks() {
    let allTasks = [];

    this.workers.forEach((worker) => {
      if (worker.userData?.tasks?.length > 0) {
        worker.userData?.tasks.forEach((task) => {
          let dates = [task.created];
          task?.comments.forEach((comment) => {
            dates.push(comment.date);
          });
          let lastModified = dates[dates.length - 1];
          task.dates = dates;
          task.lastModified = lastModified;
          if (
            //  Date.now() - task.created <  2629800000
            true
            // worker.name !== this.userDetailsService.getSimpleUserDetails().name
          ) {
            task.recipient = {
              name: worker.name,
              id: worker.id ? worker.id : worker._id,
            };
            allTasks.push(task);
          }
        });
      }
    });
    if (!this.taskPanelOpened) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.params = params;
        if (this.params.taskId) {
          const taskToOpen = allTasks.filter(
            (task) => task.id === this.params.taskId,
          )[0];
          this.openTaskViewPanel(taskToOpen);
        }
      });
    }
    const name = this.userDetailsService.getSimpleUserDetails().name;
    this.myTasks = allTasks.filter(
      (task) =>
        task.author.name == name ||
        task.recipient?.name == name ||
        task?.comments.some(
          (comment) =>
            comment.author.name ===
            this.userDetailsService.getSimpleUserDetails().name,
        ),
    );
    this.myTasks = this.helpers.sortArrayByProperty(
      this.myTasks,
      'lastModified',
      'desc',
    );
    this.conversaionTasks = this.myTasks.filter(
      (task) =>
        task.recipient.name !==
        this.userDetailsService.getSimpleUserDetails().name,
    );
    console.log(
      '🚀 ~ file: add-task.component.ts ~ line 218 ~ AddTaskComponent ~ createMyTasks ~ this.myTasks',
      this.myTasks,
    );
  }
  refreshTasks() {
    // this.spinner.show();
    this.selectedWorker = null;
    this.usersService.getWorkersList();
  }
  doTasks() {
    const todaysParsedDate = new Date(Date.now());
    const finallyParsedTodaysDate = new Date(
      this.yearlyPlanService.getDateObject(todaysParsedDate),
    );
    const sevenDaysBefore = new Date(todaysParsedDate).getDate() - 7;

    const weekBefore = new Date(
      new Date(finallyParsedTodaysDate).setDate(sevenDaysBefore),
    );
    if (this.selectedWorker?.userData?.tasks) {
      this.selectedWorker?.userData?.tasks.forEach((task) => {
        task.status = null;
        const date = task.dateScheduled;
        const parsedDate = new Date(date);
        const finallyParsedDate = new Date(
          this.yearlyPlanService.getDateObject(parsedDate),
        );
        if (
          this.yearlyPlanService.getDateObject(finallyParsedDate) ==
            this.yearlyPlanService.getDateObject(finallyParsedTodaysDate) &&
          task.completion != 'done'
        ) {
          task.status = '2forToday';
        }
        if (
          finallyParsedDate > finallyParsedTodaysDate &&
          task.completion != 'done'
        ) {
          task.status = '3forFuture';
        }
        if (task.type == 'Platforma' && task.completion != 'done') {
          task.status = 'platform';
        }
        if (
          finallyParsedDate < finallyParsedTodaysDate &&
          task.completion != 'done' &&
          task.status !== 'platform' &&
          task.type !== 'Zaległości' &&
          task.type !== 'Odrabianie'
        ) {
          task.status = '1overdue';
        }
        if (task.completion == 'done') {
          task.status = '4finished';
        }
        if (task.completion == 'pinned') {
          task.status = '5pinned';
        }
        if (task.type == 'Zaległości') {
          task.status = '6payments';
        }
        if (task.type == 'Odrabianie') {
          task.status = '7rescheduled';
        }
        if (this.userDetailsService.getSimpleUserDetails().role !== 'owner') {
          if (
            this.selectedWorker.id !==
            this.userDetailsService.getSimpleUserDetails().id
          ) {
            this.selectedWorker.userData.tasks.forEach((task, index) => {
              if (
                task.author.id !==
                this.userDetailsService.getSimpleUserDetails().id
                //    ||
                // !this.showAllTasks
              ) {
                task.dontDisplay = true;
              }
            });
          }
        }
      });
    } else {
      if (this.selectedWorker) {
        this.selectedWorker.userData.tasks = [];
        this.doTasks();
      }
    }
    // this.overdueTasks = this.selectedWorker?.userData?.tasks.filter(
    //   (task) => task.status === '1overdue',
    // );

    // this.forTodayTasks = this.selectedWorker?.userData?.tasks.filter(
    //   (task) => task.status === '2forToday',
    // );

    // this.forFutureTasks = this.selectedWorker?.userData?.tasks.filter(
    //   (task) => task.status === '3forFuture',
    // );
    // this.finishedTasks = this.selectedWorker?.userData?.tasks.filter(
    //   (task) => task.status === '4finished',
    // );
    this.pinnedTasks = this.selectedWorker?.userData?.tasks.filter(
      (task) => task.status === '5pinned',
    );
    this.paymentsTasks = this.selectedWorker?.userData?.tasks.filter(
      (task) => task.status === '6payments',
    );
    this.rescheduleLessonsTasks = this.selectedWorker?.userData?.tasks.filter(
      (task) => task.status === '7rescheduled',
    );
    console.log(
      '🚀 ~ file: add-task.component.ts:353 ~ AddTaskComponent ~ doTasks ~ this.rescheduleLessonsTasks:',
      this.rescheduleLessonsTasks,
    );
    this.platformTasks = this.selectedWorker?.userData?.tasks.filter(
      (task) => task.status === 'platform',
    );
    // this.overdueTasks = this.helpers.removeDuplicatesObj(this.overdueTasks);
    // this.overdueTasks = this.helpers.getUniqueArrayOfObjectsById(
    //   this.overdueTasks,
    // );
    // this.forTodayTasks = this.helpers.getUniqueArrayOfObjectsById(
    //   this.forTodayTasks,
    // );
    // this.forFutureTasks = this.helpers.getUniqueArrayOfObjectsById(
    //   this.forFutureTasks,
    // );
    // this.finishedTasks = this.helpers.getUniqueArrayOfObjectsById(
    //   this.finishedTasks,
    // );
    this.pinnedTasks = this.helpers.getUniqueArrayOfObjectsById(
      this.pinnedTasks,
    );
    this.paymentsTasks = this.helpers.getUniqueArrayOfObjectsById(
      this.paymentsTasks,
    );
    this.rescheduleLessonsTasks = this.helpers.getUniqueArrayOfObjectsById(
      this.rescheduleLessonsTasks,
    );
    console.log(
      '🚀 ~ file: add-task.component.ts:354 ~ AddTaskComponent ~ doTasks ~  this.paymentsTasks',
      this.paymentsTasks,
    );
    if (this.pinnedTasks?.length > 0) {
      this.pinnedTasks = this.helpers.shuffle(this.pinnedTasks);
    }
    console.log(
      '🚀 ~ file: add-task.component.ts:344 ~ AddTaskComponent ~ doTasks ~  this.pinnedTasks',
      this.pinnedTasks,
    );
    this.platformTasks = this.helpers.getUniqueArrayOfObjectsById(
      this.platformTasks,
    );
    this.finishedTasks = this.helpers.sortArrayByProperty(
      this.finishedTasks,
      'dateScheduled',
      'desc',
    );
    this.spinner.hide();
    // this.todaysTasks = this.selectedWorker.userData.task.filter(task=>)
  }
  openTaskViewPanel(task) {
    this.taskPanelOpened = true;
    const dialogRef = this.dialog.open(TaskViewComponent, {
      minWidth: '99vw',
      minHeight: '99vh',
      width: '99vw',
      height: '99vh',
      panelClass: 'hide-scrollbars',
      data: {
        task: task,
        workers: this.workers,
        selectedWorker: !task?.recipient
          ? this.selectedWorker
          : task?.recipient,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.taskPanelOpened = false;
      this.doTasks();
      this.router.navigateByUrl('/profile/tasks');
    });
  }

  addTask() {
    this.openAddTaskDialog();
  }
  panelClosed() {
    this.anyPanelOpened = false;
    if (this.refreshScheduled) {
      this.refreshTasks();
    }
  }
  openAddTaskDialog() {
    const dialogRef = this.dialog.open(AddTaskDialogComponent, {
      width: '100vw',

      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.task) {
        this.selectedWorker.userData.tasks.unshift(result.task);
        this.authorMode = true;

        this.usersService.addWorkersTasks(this.selectedWorker.id, result.task);
      }
    });
  }

  workerSelected(event) {
    this.previouslySelectedWorker = this.selectedWorker;
    this.selectedWorker = this.workers.find(
      (worker) => worker.id === event.value.id || worker.id === event.value._id,
    );

    // if (!this.selectedWorker?.userData?.tasks) {
    //   this.selectedWorker?.userData?.tasks = [];
    // }
    this.doTasks();
  }
  ngOnDestroy(): void {
    this.messagesForWorkersSub.unsubscribe();
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.taskUpdatedSub.unsubscribe();
    this.workersSub.unsubscribe();
    this.messagesForWorkersSub.unsubscribe();
  }
}
