import { Injectable } from '@angular/core';
import { types } from 'util';
import { HelpersService } from '../shared/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class Pdf2ExercisesService {
  avatars = [
    'assets/avatars/animals-in-clothes/b1.png',
    'assets/avatars/animals-in-clothes/b2.png',
    'assets/avatars/animals-in-clothes/b3.png',
    'assets/avatars/animals-in-clothes/b4.png',
    'assets/avatars/animals-in-clothes/b5.png',
    'assets/avatars/animals-in-clothes/b6.png',
    'assets/avatars/animals-in-clothes/b7.png',
    'assets/avatars/animals-in-clothes/b8.png',
    'assets/avatars/monsters/b1.png',
    'assets/avatars/monsters/b2.png',
    'assets/avatars/monsters/b3.png',
    'assets/avatars/monsters/b4.png',
    'assets/avatars/monsters/b5.png',
    'assets/avatars/monsters/b6.png',
    'assets/avatars/monsters/b7.png',
    'assets/avatars/monsters/b8.png',
    'assets/avatars/oldschool_wizard/b1.png',
    'assets/avatars/oldschool_wizard/b2.png',
    'assets/avatars/oldschool_wizard/b3.png',
    'assets/avatars/oldschool_wizard/b4.png',
    'assets/avatars/oldschool_wizard/b5.png',
    'assets/avatars/oldschool_wizard/b6.png',
    'assets/avatars/oldschool_wizard/b7.png',
    'assets/avatars/oldschool_wizard/b8.png',
    'assets/avatars/cactus-succulents/b1.png',
    'assets/avatars/cactus-succulents/b2.png',
    'assets/avatars/cactus-succulents/b3.png',
    'assets/avatars/cactus-succulents/b4.png',
    'assets/avatars/cactus-succulents/b5.png',
    'assets/avatars/cactus-succulents/b6.png',
    'assets/avatars/cactus-succulents/b7.png',
    'assets/avatars/cactus-succulents/b8.png',
    'assets/avatars/pug_in_disguise/b1.png',
    'assets/avatars/pug_in_disguise/b2.png',
    'assets/avatars/pug_in_disguise/b3.png',
    'assets/avatars/pug_in_disguise/b4.png',
    'assets/avatars/pug_in_disguise/b5.png',
    'assets/avatars/pug_in_disguise/b6.png',
    'assets/avatars/pug_in_disguise/b7.png',
    'assets/avatars/pug_in_disguise/b8.png',
    'assets/avatars/unicorns_in_disguise/b1.png',
    'assets/avatars/unicorns_in_disguise/b2.png',
    'assets/avatars/unicorns_in_disguise/b3.png',
    'assets/avatars/unicorns_in_disguise/b4.png',
    'assets/avatars/unicorns_in_disguise/b5.png',
    'assets/avatars/unicorns_in_disguise/b6.png',
    'assets/avatars/unicorns_in_disguise/b7.png',
    'assets/avatars/unicorns_in_disguise/b8.png',
    'assets/avatars/animals-in-clothes/p1.png',
    'assets/avatars/animals-in-clothes/p2.png',
    'assets/avatars/animals-in-clothes/p3.png',
    'assets/avatars/animals-in-clothes/p4.png',
    'assets/avatars/animals-in-clothes/p5.png',
    'assets/avatars/animals-in-clothes/p6.png',
    'assets/avatars/animals-in-clothes/p7.png',
    'assets/avatars/animals-in-clothes/p8.png',
    'assets/avatars/monsters/p1.png',
    'assets/avatars/monsters/p2.png',
    'assets/avatars/monsters/p3.png',
    'assets/avatars/monsters/p4.png',
    'assets/avatars/monsters/p5.png',
    'assets/avatars/monsters/p6.png',
    'assets/avatars/monsters/p7.png',
    'assets/avatars/monsters/p8.png',
    'assets/avatars/oldschool_wizard/p1.png',
    'assets/avatars/oldschool_wizard/p2.png',
    'assets/avatars/oldschool_wizard/p3.png',
    'assets/avatars/oldschool_wizard/p4.png',
    'assets/avatars/oldschool_wizard/p5.png',
    'assets/avatars/oldschool_wizard/p6.png',
    'assets/avatars/oldschool_wizard/p7.png',
    'assets/avatars/oldschool_wizard/p8.png',
    'assets/avatars/cactus-succulents/p1.png',
    'assets/avatars/cactus-succulents/p2.png',
    'assets/avatars/cactus-succulents/p3.png',
    'assets/avatars/cactus-succulents/p4.png',
    'assets/avatars/cactus-succulents/p5.png',
    'assets/avatars/cactus-succulents/p6.png',
    'assets/avatars/cactus-succulents/p7.png',
    'assets/avatars/cactus-succulents/p8.png',
    'assets/avatars/pug_in_disguise/p1.png',
    'assets/avatars/pug_in_disguise/p2.png',
    'assets/avatars/pug_in_disguise/p3.png',
    'assets/avatars/pug_in_disguise/p4.png',
    'assets/avatars/pug_in_disguise/p5.png',
    'assets/avatars/pug_in_disguise/p6.png',
    'assets/avatars/pug_in_disguise/p7.png',
    'assets/avatars/pug_in_disguise/p8.png',
    'assets/avatars/unicorns_in_disguise/p1.png',
    'assets/avatars/unicorns_in_disguise/p2.png',
    'assets/avatars/unicorns_in_disguise/p3.png',
    'assets/avatars/unicorns_in_disguise/p4.png',
    'assets/avatars/unicorns_in_disguise/p5.png',
    'assets/avatars/unicorns_in_disguise/p6.png',
    'assets/avatars/unicorns_in_disguise/p7.png',
    'assets/avatars/unicorns_in_disguise/p8.png',
  ];
  waiters = [32];

  childrenPdfTypes = [
    {
      name: 'children-tracing-easy',
      displayName: 'Ślad i dopasowanie',
      selected: false,
      descriptionPl: 'Popraw słowa po śladzie i połącz z tłumaczeniami.',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-scramble',
      displayName: 'Rozsypanka',
      selected: false,
      descriptionPl: 'Rozszyfruj słowa',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-scramble-harder',
      displayName: 'Rozsypanka (trudniejsza)',
      selected: false,
      descriptionPl: 'Rozszyfruj słowa i dopasuj je do obrazków',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-dictionary',
      displayName: 'Słowniczek',
      selected: false,
      descriptionPl: 'Słowniczek.',
      descriptionEn: 'Dictionary.',
      type: 'children',
    },
    {
      name: 'children-rewrite-word-from-sentence',
      displayName: 'Przepisz słowo ze zdania',
      selected: false,
      descriptionPl: 'Przepisz słowo ze zdania.',
      descriptionEn: 'Rewrite word from sentence.',
      type: 'children',
    },
    {
      name: 'children-choose-word-from-two',
      displayName: 'Wybierz słowo z dwóch',
      selected: false,
      descriptionPl: 'Skreśl niewłaściwe słowo.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-snake',
      displayName: 'Ślimak',
      selected: false,
      descriptionPl: 'Wyszukaj słowa.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-words',
      displayName: 'Uzupełnij słowa',
      selected: false,
      descriptionPl: 'Uzupełnij słowa',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-sentences-easy',
      displayName: 'Uzupełnij zdania',
      selected: false,
      descriptionPl: 'Uzupełnij zdania',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-make-sentences-easy',
      displayName: 'Utwórz zdania z rozsypanki',
      selected: false,
      descriptionPl: 'Utwórz zdania z rozsypanki',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-words-harder',
      displayName: 'Uzupełnij słowa (trudniejsze)',
      selected: false,
      descriptionPl: 'Wpisz słowa',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },

    {
      name: 'children-wordsearch',
      displayName: 'Wyszukaj słowa',
      selected: false,
      descriptionPl: 'Wyszukaj słowa.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-crossword',
      displayName: 'Krzyżówka',
      selected: false,
      descriptionPl: 'Uzupełnij krzyżówkę.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
  ];
  sentencesPdfTypes = [
    {
      name: 'sentences-fill-gaps-no-clues',
      displayName: 'Uzupełnij luki bez podpowiedzi',
      selected: false,
      descriptionPl: 'Uzupełnij luki.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-picture-dictionary',
      displayName: 'Słowniczek obrazkowy',
      selected: false,
      numberOfItems: 32,
      descriptionPl: 'Dopasuj słowa do obrazków.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-dialogue-fill-gaps',
      displayName: 'Uzupełnij luki w dialogu',
      selected: false,
      descriptionPl: 'Uzupełnij luki w dialogu',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-fill-gaps',
      displayName: 'Uzupełnij luki w tekście',
      selected: false,
      descriptionPl: 'Uzupełnij luki w tekście',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Uzupełnij luki z podpowiedziami 10 słów',
      selected: false,
      numberOfItems: 10,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Uzupełnij luki z podpowiedziami 15 słów',
      selected: false,
      numberOfItems: 15,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Uzupełnij luki z podpowiedziami 20 słów',
      selected: false,
      numberOfItems: 20,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-mixed',
      displayName: 'Przetłumacz i dopasuj słowa',
      selected: false,
      descriptionPl:
        'Przetłumacz i dopasuj słowa, a następnie zapisz je po angielsku obok tłumaczeń.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps',
      displayName: 'Przetłumacz i uzupełnij luki 2 strony',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-mixed+sentences-fill-gaps-no-clues',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-random-letters',
      displayName: 'Przetłumacz i uzupełnij luki 2 strony z losowymi literami',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset:
        'sentences-translate-mixed+sentences-fill-gaps-no-clues-random-letters',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-all-items',
      displayName: 'Przetłumacz i uzupełnij luki wszystkie słowa z zestawu',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-fill-gaps-all-items',
      type: 'sentences',
    },
    {
      name: 'sentences-flashcards-all-items',
      displayName: 'Flashcards - printable',
      preset: 'sentences-flashcards-all-items',
      selected: false,
      numberOfItems: 12,
      descriptionPl: '********',
      descriptionEn: '********',
      type: 'printable',
    },
    {
      name: 'sentences-translate-fill-gaps-all-items-all-sets',
      displayName:
        'Przetłumacz i uzupełnij luki wszystkie słowa ze wszystkich zestawów',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-fill-gaps-all-items-all-sets',
      multiSet: true,
      type: 'sentences',
    },
  ];
  freeSetPdfTypes = [
    {
      name: 'fill-gaps-answers-no-clues',
      displayName: 'zadania z luką + odpowiedzi wszystko z zestawu',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues-single-exercise',
      displayName: 'zadania z luką + odpowiedzi pojedyncze zadanie',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues-single-exercise',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues',
      displayName: 'zadania bez luki + odpowiedzi wszystko z zestawu',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues',
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues-single-exercise',
      displayName: 'zadania z bez luki odpowiedzi pojedyncze zadanie',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues-single-exercise',
      type: 'freeset',
    },

    {
      name: 'answers-gaps',
      displayName: 'Odpowiedzi',
      selected: false,
      descriptionPl: 'Odpowiedzi',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'freeset-fill-gaps-sentences-clues',
      displayName: 'Uzupełnij luki z podpowiedziami',
      selected: false,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-fill-gaps-sentences-no-clues',
      displayName: 'Uzupełnij luki bez podpowiedzi',
      selected: false,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-no-gaps-translate-answer-clues',
      displayName: 'Wpisz właściwą odpowiedź z podpowiedziami',
      selected: false,
      descriptionPl: 'Wpisz odpowiednie słowo w lukę',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-no-gaps-translate-answer',
      displayName: 'Wpisz właściwą odpowiedź bez podpowiedzi',
      selected: false,
      descriptionPl: 'Wpisz odpowiednie słowo w lukę',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'dialogues-match',
      displayName: 'Dopasuj dialogi (wszystko z zestawu)',
      inOrder: true,
      allItems: true,
      selected: false,
      descriptionPl: 'Dopasuj zdania',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'reading-with-questions-with-clues',
      allItems: true,
      displayName: 'Czytanie z podpowiedziami',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-story-text',
      preset: 'freeset-story-text',
      allItems: false,
      displayName: 'Historia - Tekst',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Przeczytaj tekst.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-sentences-dictionary',
      allItems: false,
      displayName: 'Zdania - słownik',
      numberOfItems: 30,
      selected: false,
      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-sentences-match',
      allItems: false,
      displayName: 'Zdania - dopasuj',
      selected: false,
      numberOfItems: 30,

      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-story-snail',
      allItems: false,
      displayName: 'Historia - ślimak',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz słowa w ślimaku.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'reading-with-questions-with-clues',
      allItems: true,
      displayName: 'Ułóż historię w kolejności',
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Wpisz numerki zdań w odpowiedniej kolejności.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'conversations-choose',
      allItems: true,
      displayName: 'Konwersacja z podpowiedziami',
      inOrder: true,
      selected: false,
      descriptionPl: 'Zaznacz dowolną odpowiedź',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
  ];
  constructor(private helpers: HelpersService) {}
  getDescription(taskName, taskDisplayName, lang: 'pl' | 'en') {
    let result = '';
    let type;
    let types = this.helpers.flatenArray(
      this.childrenPdfTypes
        .concat(this.freeSetPdfTypes)
        .concat(this.sentencesPdfTypes)
    );
    if (lang == 'pl') {
      type = types.find((_type) => _type.name === taskName);
      result = type?.descriptionPl;
    }
    if (lang == 'en') {
      type = types.find((_type) => _type.name === taskName);
      result = type?.descriptionEn;
    }

    return result;
  }
}
