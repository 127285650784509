import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.css'],
})
export class AddTaskDialogComponent implements OnInit {
  taskToAdd = null;
  taskTypes = [
    'Klienci',
    'Odrabianie',
    'Płatności',
    'Zaległości',
    'Księgowa',
    'Platforma',
    'Rezygnacje',
    'Inne',
  ];
  taskImportances = ['Pilne', 'Ważne'];
  sub: any;
  constructor(
    private chatService: ChatService,
    private dialogRef: MatDialogRef<AddTaskDialogComponent>,
    private dialog: MatDialog,
    private helper: HelpersService,
    private userDetailsService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: { editMode: boolean; task: any }
  ) { }

  ngOnInit(): void {
    this.sub = this.chatService
      .chatMessagesListener()
      .subscribe((messages: any) => {
        console.log(
          '🚀 ~ file: add-task-dialog.component.ts:41 ~ AddTaskDialogComponent ~ this.sub=this.chatService.chatMessagesListener ~ messages:',
          messages
        );
        this.taskToAdd.text = messages.res.choices[0].message.content;
      });
    if (!this.data?.editMode) {
      this.taskToAdd = this.getEmptyTask();
    } else {
      this.taskToAdd = this.data.task;
    }
  }
  setCurrentDate() {
    const localDate = new Date();
    localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset()); // Adjust for timezone offset
    this.taskToAdd.dateScheduled = localDate.toISOString().slice(0, 16);

    console.log(
      '🚀 ~ file: add-task-dialog.component.ts ~ line 38 ~ AddTaskDialogComponent ~ setCurrentDate ~ this.taskToAdd.dateScheduled',
      this.taskToAdd.dateScheduled
    );
  }
  getEmptyTask(): any {
    const emptyTask = {
      id: this.helper.makeid(),
      text: '',
      title: '',
      seenBy: [this.userDetailsService.getSimpleUserDetails().id],
      comments: [],
      lastModified: 0,
      importance: '',
      problematic: false,
      created: Date.now(),
      dateScheduled: null,
      completion: 'todo',
      type: '',
      tags: [],
      author: this.userDetailsService.getSimpleUserDetails(),
    };
    return emptyTask;
  }
  correctText(text) {
    if (text.length > 10) {
      this.chatService.correctPolishText(text);
    } else {
      alert('Za krótki tekst');
    }
  }
  addTask() {
    if (this.taskToAdd.text.length > 0 && this.taskToAdd.title.length > 0) {
      this.dialogRef.close({ task: this.taskToAdd });
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
