import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  addMinutes,
  setHours,
  setMinutes,
  differenceInMinutes,
  isDate,
} from 'date-fns';
import { SmallDataService } from 'src/app/shared/services/small-data.service';
import { PlanTempGroupComponent } from '../../plan-temp-group/plan-temp-group.component';
import { UserDetailsService } from 'src/app/profile/user-details.service';
@Component({
  selector: 'app-schedule-add-group',
  templateUrl: './schedule-add-group.component.html',
  styleUrls: ['./schedule-add-group.component.css'],
})
export class ScheduleAddGroupComponent implements OnInit {
  @ViewChild(PlanTempGroupComponent) planComponent;
  duration = 0;
  group = {
    dbId: '',
    id: Date.now(),
    title: '',
    start: new Date(),
    duration: 45,
    end: addMinutes(new Date(), 45),
    draggable: true,
    resizable: {
      beforeStart: true, // this allows you to configure the sides the event is resizable from
      afterEnd: true,
    },

    meta: {
      tmpEvent: true,
    },
  };
  editMode: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ScheduleAddGroupComponent>,
    private smallDataService: SmallDataService,
    public userDetailsService: UserDetailsService,
  ) {}

  ngOnInit(): void {
    if (this.data.groupToEdit) {
      this.editMode = true;
      this.group = this.data.groupToEdit;
      console.log(
        '🚀 ~ file: schedule-add-group.component.ts ~ line 43 ~ ScheduleAddGroupComponent ~ ngOnInit ~ this.group',
        this.group,
      );
      this.countDuration();
    }
  }
  countDuration() {
    const diff = differenceInMinutes(this.group.end, this.group.start);
    if (diff > 0) {
      this.duration = diff;
    }
  }
  setEventsTime(event, mode: 'start' | 'end') {
    const hour = event.currentTarget.value.split(':')[0];
    const minutes = event.currentTarget.value.split(':')[1];
    if (hour.length > 0 && minutes.length > 0) {
      let newDate = setHours(this.group[mode], hour);
      newDate = setMinutes(this.group[mode], minutes);
      if (isDate(newDate)) {
        this.group[mode] = setHours(this.group[mode], hour);
        this.group[mode] = setMinutes(this.group[mode], minutes);
      }

      this.countDuration();
    }
  }
  countEndTime(event) {
    this.group.end = addMinutes(this.group.start, this.group.duration);
  }
  removeTempGroup() {
    if (confirm('Czy na pewno usunąć grupę ' + this.group.title + '?'))
      this.smallDataService.removeTempGroup(this.group.dbId);
    this.dialogRef.close();
  }
  addGroup(edit?) {
    const setValuesPromise = new Promise<void>((resolve, reject) => {
      if (this.planComponent.checkAvalibility()) {
        setTimeout(() => {
          this.planComponent.setTimes(true);
          resolve(this.planComponent.tempGroup);
        }, 300);
      } else {
        if (confirm('Czy na pewno chcesz dodać grupę?')) {
          this.planComponent.setTimes(true);

          resolve(this.planComponent.tempGroup);
        } else {
          reject('student not avalible');
        }
      }
    });
    setValuesPromise.then((result) => {
      this.smallDataService.updateTempGroup(result);
      this.dialogRef.close(result);
    });
  }
}
