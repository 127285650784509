import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinancesStatsService {
  teachersCosts = [
    { name: 'Piotr Szyszka', value: 60 },
    { name: 'Olga Gosk', value: 50 },
    { name: 'Weronika Oczki', value: 40 },
    { name: 'Joanna Staniszewska', value: 40 },
    { name: 'Julia Ziemka', value: 50 },
    { name: 'Ernest Essuman-Mensah', value: 60 },
    { name: 'Agnieszka Jacek', value: 0 },
    { name: 'Krzysztof Jacek', value: 0 },
    { name: 'Karolina Frasz ', value: 50 },
  ]
  financesData = {
    incomes: [],
    expenses: [],
    states: [
      { name: 'Konto', value: 35700 },
      { name: 'Gotówka', value: 18000 },
      { name: 'Niezapłacone', value: 24100 },
      { name: 'Strata', value: -1000 },

    ],

  }


  constructor() {

  }
}
