<div
  style="height: 94vh; overflow-y: hidden; overflow-x: hidden"
  fxLayout="column"
>
  <div style="overflow-x: hidden; height: 95vh" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <span style="font-size: 1.3em; font-weight: bold">{{
        task?.author.name + ":"
      }}</span>
      <img
        *ngFor="let user of task?.seenBy"
        width="20px"
        height="20px"
        [src]="workersAvatarsService.getAvatarURLById(user)"
        (longpress)="$event.preventDefault(); $event.stopPropagation()"
        (touchstart)="$event.preventDefault(); $event.stopPropagation()"
        (click)="$event.stopPropagation()"
        [matTooltip]="workersAvatarsService.getNameFromId(user)"
        matTooltipTouchGestures="true"
      />
    </div>
    <mat-chip-list style="width: 100%" class="mt-4">
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start start"
        fxLayoutAlign.gt-sm="start start"
      >
        <div style="width: 4px"></div>
        <img
          (longpress)="$event.preventDefault(); $event.stopPropagation()"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
          (click)="$event.stopPropagation()"
          [matTooltip]="task?.author.name"
          matTooltipTouchGestures="true"
          width="40px"
          height="40px"
          [src]="workersAvatarsService.getAvatarURL(task?.author.name)"
        />
        <mat-chip
          selected
          color="primary"
          style="font-weight: bolder; overflow-x: hidden"
          >{{ task?.title + ": " + task?.text }}
        </mat-chip>
      </div>
    </mat-chip-list>
    <span
      class="mt-2 mb-2"
      style="
        font-size: 1.2em;
        font-weight: 500;

        overflow: hidden;
      "
    >
    </span>
    <div
      class="mt-3"
      style="width: 100%"
      fxLayout="column"
      #comments
      tabindex="1"
      *ngFor="let comment of task?.comments"
    >
      <div
        style="width: 100%"
        class="mb-1 mt-1"
        style="font-weight: bolder"
        fxLayout="row"
        fxFlex
        fxLayoutGap="10px"
      >
        <span>{{ comment?.author.name }}</span>
        <span>{{ comment?.date | date: "EEEEEE, dd.MM.yy HH:mm" }} </span>
        <div fxLayout="row" fxLayoutGap="5px">
          <img
            [src]="workersAvatarsService.getAvatarURLById(user)"
            *ngFor="let user of comment.lastSeenBy"
            (longpress)="$event.preventDefault(); $event.stopPropagation()"
            (touchstart)="$event.preventDefault(); $event.stopPropagation()"
            (click)="$event.stopPropagation()"
            [matTooltip]="workersAvatarsService.getNameFromId(user)"
            matTooltipTouchGestures="true"
            width="25px"
            height="25px"
          />
        </div>
        <span fxFlex></span>
        <!-- <mat-icon
          *ngIf="comment.author.id === userDetailsService.getSimpleUserDetails().id || this.userDetailsService.getSimpleUserDetails().role === 'owner'"
          (click)="removeComment(comment, task)"
          class="pointer"
          color="warn"
          >remove_circle_outline</mat-icon
        > -->
      </div>
      <mat-divider class="mb-3"></mat-divider>
      <mat-chip-list style="width: 100%">
        <div style="width: 100%" fxLayout="row" fxLayoutGap="10px">
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            style="width: 100%"
            [fxLayoutAlign]="
              comment.author.id === task?.author.id
                ? 'start start'
                : 'end start'
            "
          >
            <div
              fxHide
              [fxShow]="comment.author.id === task?.author.id"
              fxLayout="row"
            >
              <div style="width: 4px"></div>
              <img
                (longpress)="$event.preventDefault(); $event.stopPropagation()"
                (touchstart)="$event.preventDefault(); $event.stopPropagation()"
                (click)="$event.stopPropagation()"
                [matTooltip]="comment?.author.name"
                matTooltipTouchGestures="true"
                width="40px"
                height="40px"
                [src]="workersAvatarsService.getAvatarURL(comment?.author.name)"
              />
            </div>
            <mat-chip
              [color]="comment?.new ? 'warn' : 'primary'"
              style="max-width: 85%"
              [selected]="comment.author.id === task?.author.id"
              >{{ comment?.text }}
            </mat-chip>
            <div
              fxHide
              [fxShow]="comment.author.id !== task?.author.id"
              fxLayout="row"
            >
              <img
                (longpress)="$event.preventDefault(); $event.stopPropagation()"
                (touchstart)="$event.preventDefault(); $event.stopPropagation()"
                (click)="$event.stopPropagation()"
                [matTooltip]="comment?.author.name"
                matTooltipTouchGestures="true"
                width="40px"
                height="40px"
                [src]="workersAvatarsService.getAvatarURL(comment?.author.name)"
              />
              <div style="width: 4px"></div>
            </div>
          </div>
        </div>
      </mat-chip-list>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <span class="ml-2 mt-3" style="font-weight: bold">Dodaj do rozmowy </span>
    <hr />
    <button
      mat-menu-item
      (click)="addToConversation(task, worker)"
      *ngFor="let worker of workers"
    >
      <img class="mr-2" style="width: 35px" [src]="worker.avatar" alt />{{
        worker.name
      }}
    </button>
  </mat-menu>
  <mat-radio-group
    *ngIf="selectedWorker?.id === userDetailsService.getSimpleUserDetails().id"
    [(ngModel)]="task.completion"
    (change)="taskCompletionChanged(task)"
  >
    <mat-radio-button value="todo">Do zrobienia &nbsp; </mat-radio-button>
    <!-- <mat-radio-button
      value="partiallyDone"
      [ngClass]="{'ml-3': (!deviceSizeService.isHandset | async)}"
      >Częściowo wykonane &nbsp;</mat-radio-button
    > -->
    <mat-radio-button value="pinned">Przypięte &nbsp; </mat-radio-button>
    <mat-radio-button value="done">Wykonane &nbsp; </mat-radio-button>
  </mat-radio-group>
  <div style="width: 100%" fxLayoutAlign="center center">
    <mat-form-field
      style="width: 100%; margin-bottom: -1.25em"
      appearance="outline"
    >
      <mat-label>Dodaj komentarz</mat-label>
      <textarea
        #input
        matInput
        (keydown.enter)="
          $event.preventDefault(); $event.stopPropagation(); addComment(task)
        "
        [(ngModel)]="commentToAdd"
      ></textarea>
    </mat-form-field>
    <button
      *ngIf="userDetailsService.owner"
      (click)="correctText()"
      color="primary"
      mat-icon-button
    >
      <mat-icon>help</mat-icon>
    </button>
    <button (click)="addComment(task)" color="primary" mat-icon-button>
      <mat-icon>send</mat-icon>
    </button>
  </div>
  <div fxLayout="row" style="width: 100%" fxLayoutGap="10px">
    <button
      matTooltip="Usuń"
      *ngIf="
        task?.author.id === userDetailsService.getSimpleUserDetails().id ||
        this.userDetailsService.getSimpleUserDetails().role === 'owner'
      "
      class="mt-2"
      (click)="removeTask(task)"
      color="warn"
      mat-icon-button
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
    <button
      matTooltip="Edytuj"
      *ngIf="
        task?.author.id === userDetailsService.getSimpleUserDetails().id ||
        this.userDetailsService.getSimpleUserDetails().role === 'owner'
      "
      class="mt-2"
      (click)="editTask(task)"
      color="primary"
      mat-icon-button
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      fxFlex
      class="mt-2"
      mat-dialog-close
      color="primary"
      mat-flat-button
    >
      Zamknij
    </button>
    <button
      (click)="addComment(task, true)"
      matTooltip="Oznacz jako przeczytane"
      class="mt-2"
      color="primary"
      mat-icon-button
    >
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      [matMenuTriggerFor]="menu"
      matTooltip="Dodaj osobę do rozmowy"
      class="mt-2"
      color="primary"
      mat-icon-button
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
</div>
