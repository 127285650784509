<div [class]="title === 'Zaległe'? null : 'mt-3 mb-3'">
    <div style="width: 100%;">
        <span
            [style.color]="title === 'Zaległe'? 'red':'black'"
            style="width: 100%; font-size: 1.3em;"
            fxLayoutAlign="start"
        >
            <span>{{title}}</span>
        </span>
    </div>
    <mat-divider class="mt-2"></mat-divider>
</div>
<div *ngFor="let task of tasks let i = index">
    <div
        #workersTasks
        class="p-1"
        *ngIf="(!task.dontDisplay && i < limit) || showAllTasks"
    >
        <mat-card
            [style.border]="checkNewComment(task) ? '3px solid #673ab7' : null"
            (click)="openTaskViewPanel(task)"
            [ngClass]="{'border-done': task.completion =='done','border-partially-done': task.completion =='partiallyDone', 'border-todo': task.completion == 'todo', 'border-attention': task.completion == null}"
        >
            <div
                class="pl-1"
                [collapsedHeight]="(deviceSizeService.isHandset | async)? '80px':'60px' "
                fxLayout="row"
            >
                <mat-panel-title
                    [style.font-weight]="task?.seenBy?.includes(this.userDetailsService.getSimpleUserDetails().id)? '' : 'bolder'"
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    [style.width]="(deviceSizeService.isHandset | async)? '70%': '50%'"
                >
                    <div
                        fxLayoutAlign="start start"
                        fxLayout="column"
                    >
                        <div
                            style="width: 100%;"
                            fxLayoutAlign="start start"
                            fxLayout="column"
                            fxFlex
                        >
                            <div
                                class="mb-1"
                                fxLayout="row wrap"
                                fxLayoutGap="10px"
                            >
                                <div
                                    style="white-space: nowrap;"
                                    fxLayoutAlign="start center"
                                    fxLayoutGap="10px"
                                >
                                    <img
                                        (longpress)="$event.preventDefault(); $event.stopPropagation()"
                                        (touchstart)="$event.preventDefault(); $event.stopPropagation()"
                                        (click)="$event.stopPropagation()"
                                        [matTooltip]="workersAvatarsService.getNameFromId(user)"
                                        matTooltipTouchGestures="true"
                                        [class]="checkImgDisabled(task, user) ? 'img-disabled' : ''"
                                        *ngFor="let user of task.recipients"
                                        width="25px"
                                        height="25px"
                                        [src]="workersAvatarsService.getAvatarURLById(user)"
                                     />
                                    <span>{{task?.dateScheduled | date : 'EEEEEE, dd.MM.yy': 'null':
                  locale}}
                                    </span>
                                </div>
                            </div>
                            <!-- <div fxLayout="row" style="font-size: 0.8em;">
                <span *ngIf="checkNewComment(task)">
                  Nowy komentarz od
                </span>
                <span style="white-space: nowrap;"
                  >{{task?.comments[task.comments.length-1]?.author?.name}}</span
                >
              </div> -->
                        </div>
                        <div
                            class="mt-2"
                            fxFlex
                            fxLayoutAlign="center center"
                            fxLayoutGap="10px"
                        >
                            <mat-icon
                                style="width: 25px;"
                                color="primary"
                            >article
                            </mat-icon>
                            <span
                                style="font-size: 1.4em; text-overflow: ellips;"
                                [style.color]="task.status === '1overdue' && task?.completion !== 'done'? 'red': !task?.seenBy?.includes(userDetailsService.getSimpleUserDetails().id)?  '#673ab7' : '#9e9e9e'"
                                [style.text-decoration]="task.completion === 'done'? 'line-through': null"
                                class="text"
                                [style.width]="(deviceSizeService.isHandset | async)? '60vw': '35vw'"
                            >{{task?.title? task.title : task.text.slice(0, 20) + '...'
                }}
                            </span>
                        </div>

                    </div>
                </mat-panel-title>
                <span fxFlex></span>
                <div fxLayout="column">
                    <mat-icon
                        [style.color]="task.importance === 'Pilne' ? '#651fff' : task.importance === 'Ważne' ? '#7c4dff' : task.importance === 'Niezbyt ważne' ? '#b388ff' : 'white' "
                        class="mr-3"
                    >support_agent
                    </mat-icon>
                    <mat-icon
                        *ngIf="task.completion"
                        [style.color]="task.completion === 'done' ? '#9ccc65' : task.completion === 'partiallyDone' ? '#ffeb3b' : task.completion === 'todo' ? '#f57c00' : 'white' "
                        class="mr-3"
                    >check_circle_outline
                    </mat-icon>
                    <mat-icon
                        *ngIf="!task.completion"
                        color="warn"
                        class="mr-3"
                    >error
                    </mat-icon>
                </div>
            </div>
            <!-- <div *ngIf="task.comments.length > 0 && task?.comments[task.comments.length-1]?.text == 'Przeczytano'">
                <hr />
                <div
                    style="font-size: 0.8em; "
                    class="mt-1"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                >
                    <img
                        *ngIf="task?.comments[task.comments.length-1]?.text == 'Przeczytano'"
                        width="15px"
                        [src]="workersAvatarsService.getAvatarURL(task?.comments[task.comments.length-1]?.author?.name)"
                        alt
                     />
                    <span
                        *ngIf="task?.comments[task.comments.length-1]?.text == 'Przeczytano'"
                        style="white-space: nowrap;"
                    >{{task?.comments[task.comments.length-1]?.author?.name}}:
                    </span>
                    <span
                        *ngIf="task?.comments[task.comments.length-1]?.text == 'Przeczytano'"
                        style="
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 35vw;
        "
                    >{{task?.comments[task.comments.length-1]?.text}}
                    </span>
                </div>
            </div> -->
        </mat-card>
    </div>
</div>
<button
    style="width:100%"
    mat-flat-button
    color="primary"
    *ngIf="tasks?.length >= limit "
    (click)="showAllTasks = !showAllTasks"
>
    <div
        fxLayout="row"
        fxLayoutAlign="center center"
    >

        <!-- <mat-spinner class="mr-2" color="accent"
  diameter="20">
</mat-spinner> -->
        <span>Pokaz wszystko</span>
    </div>
</button>
