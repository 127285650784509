<!-- <div [style.height]="internal? '85vh': null"> -->
<form
  class="p-2"
  style="width: 100%"
  [style.background-color]="bgColor"
  [style.height]="internal ? '85vh' : '100%'"
  [formGroup]="signUpData"
  (ngSubmit)="onSubmit()"
>
  <h3 *ngIf="!shortVersion && !shortestVersion" class="ml-2">
    W celu zapisania słuchacza na kurs języka angielskiego w roku szkolnym
    2023/2024 prosimy o podanie następujących danych:
  </h3>
  <h3 *ngIf="shortestVersion" class="ml-2">
    Porozmawiajmy! Zostaw nam kontakt, a my oddzwonimy i opowiemy, dlaczegoto
    właśnie z nami warto się uczyć.
  </h3>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center stretch"
    fxLayout.xs="column"
    fxLayoutGap.gt-xs="10px"
  >
    <mat-form-field
      *ngIf="!shortVersion && !shortestVersion"
      fxFlex.xs="100"
      fxFlex="48"
      appearance="outline"
    >
      <mat-label>Lokalizacja</mat-label>
      <mat-select placeholder="Wybierz..." formControlName="location">
        <mat-option value="Bydgoszcz">Bydgoszcz</mat-option>
        <mat-option value="bialeblota">Biale Blota</mat-option>
        <mat-option value="Online">Online</mat-option>
        <mat-option value="nieznana">Nieznana</mat-option>
      </mat-select>
      <mat-error>Pole wymagane!</mat-error>
    </mat-form-field>
  </div>
  <div class="mb-3" fxLayout="column">
    <h1 *ngIf="!shortVersion && !shortestVersion" class="pt-4">Dane Klienta</h1>
    <button
      (click)="getCustomerFromExisting()"
      type="button"
      style="widdth: 96%"
      *ngIf="internal"
      type="null"
      mat-flat-button
      color="primary"
    >
      pobierz z obecnych klientów
    </button>
  </div>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="center stretch"
    fxLayout.xs="column"
    fxLayoutGap.gt-xs="10px"
  >
    <mat-form-field
      class="white-background"
      fxFlex.xs="100"
      [fxFlex]="shortestVersion ? '33' : '48'"
      appearance="outline"
    >
      <mat-label>Imię</mat-label>
      <input formControlName="customerFirstName" type="text" matInput />
      <mat-error>Pole wymagane!</mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="!shortestVersion"
      fxFlex.xs="100"
      [fxFlex]="shortestVersion ? 25 : 48"
      appearance="outline"
    >
      <mat-label>Nazwisko</mat-label>
      <input formControlName="customerLastName" type="text" matInput />
      <mat-error>Pole wymagane!</mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="shortestVersion"
      fxFlex.xs="100"
      fxFlex="25"
      appearance="outline"
    >
      <mat-label>Telefon</mat-label>
      <input formControlName="customerPhone" type="tel" matInput />
      <mat-error>Pole wymagane!</mat-error>
    </mat-form-field>
    <button
      [disabled]="confirmation"
      type="submit"
      fxFlex="15"
      mat-flat-button
      class="mt-2"
      color="primary"
      style="height: 40px"
      *ngIf="shortestVersion"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        Wyślij<mat-icon *ngIf="!formSubmitted || !signUpData.valid" class="ml-2"
          >send</mat-icon
        ><mat-spinner
          class="ml-2"
          *ngIf="formSubmitted && signUpData.valid"
          color="accent"
          diameter="20"
        ></mat-spinner>
      </div>
    </button>
  </div>

  <div
    *ngIf="!shortestVersion"
    fxLayout="row wrap"
    fxLayoutAlign="center stretch"
    fxLayout.xs="column"
    fxLayoutGap.gt-xs="10px"
  >
    <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
      <mat-label>Telefon</mat-label>
      <input formControlName="customerPhone" type="tel" matInput />
      <mat-error>Pole wymagane!</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
      <mat-label>E-mail</mat-label>
      <input formControlName="customerEmail" type="email" matInput />
      <mat-error *ngIf="signUpData.get('customerEmail').hasError('email')"
        >E-mail nieprawidłowy!</mat-error
      >
      <mat-error *ngIf="signUpData.get('customerEmail').hasError('required')"
        >Pole wymagane!</mat-error
      >
    </mat-form-field>
  </div>
  <div *ngIf="!shortVersion && !shortestVersion">
    <div *ngFor="let _ of students.controls; index as i">
      <ng-container formArrayName="students">
        <ng-container [formGroupName]="i">
          <h1 class="mt-3">Dane słuchacza:</h1>
          <div class="ml-3 mb-2">
            <mat-label ngClass="labelBeforeSlide">Dziecko</mat-label>

            <mat-slide-toggle (change)="changeStudent($event, i)" color="accent"
              >Osoba dorosła</mat-slide-toggle
            >
            <span class="ml-2"></span>
            <mat-checkbox
              (change)="copyData($event, i)"
              *ngIf="_.get('child').value == false"
              color="accent"
              >skopiuj dane
            </mat-checkbox>
          </div>
          <div *ngIf="_.get('child').value == true">
            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap.gt-xs="10px"
            >
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Imię</mat-label>
                <input formControlName="childFirstName" type="text" matInput />
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Nazwisko</mat-label>
                <input formControlName="childLastName" type="text" matInput />
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
            </div>

            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap.gt-xs="10px"
            >
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Klasa</mat-label>
                <mat-select formControlName="childGrade">
                  <mat-option value="przedszkole">przedszkole</mat-option>
                  <mat-option value="zerówka">zerówka</mat-option>
                  <mat-option value="1 klasa">1 klasa</mat-option>
                  <mat-option value="2 klasa">2 klasa</mat-option>
                  <mat-option value="3 klasa">3 klasa</mat-option>
                  <mat-option value="4 klasa">4 klasa</mat-option>
                  <mat-option value="5 klasa">5 klasa</mat-option>
                  <mat-option value="6 klasa">6 klasa</mat-option>
                  <mat-option value="7 klasa">7 klasa</mat-option>
                  <mat-option value="8 klasa">8 klasa</mat-option>
                  <mat-option value="1 klasa liceum/technikum"
                    >1 klasa liceum/technikum</mat-option
                  >
                  <mat-option value="2 klasa liceum/technikum"
                    >2 klasa liceum/technikum</mat-option
                  >
                  <mat-option value="3 klasa liceum/technikum"
                    >3 klasa liceum/technikum</mat-option
                  >
                  <mat-option value="4 klasa liceum/technikum"
                    >4 klasa liceum/technikum</mat-option
                  >
                </mat-select>
                <mat-hint>Klasa w nowym roku szkolnym 2022/2023</mat-hint>
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Poziom</mat-label>
                <mat-select formControlName="childLevel">
                  <mat-option value="Bardzo dobrze (5-6)"
                    >Bardzo dobrze (5-6)</mat-option
                  >
                  <mat-option value="Dobrze (4)"> Dobrze (4)</mat-option>
                  <mat-option value="Średnio(2-3)">Średnio(2-3)</mat-option>
                  <mat-option value="Słabiej(1)">Słabiej(1)</mat-option>
                </mat-select>
                <mat-hint>Jak dziecko sobie radzi z angielskiego</mat-hint>
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
            </div>

            <h3 class="ml-2 mt-4">
              Czy dziecko uczęszczało wcześniej na dodatkowe zajęcia
              pozalekcyjne lub korepetycje z jezyka angielskiego?
            </h3>

            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap.gt-xs="10px"
            >
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Wybierz...</mat-label>
                <mat-select formControlName="childPreviousClasses">
                  <mat-option value="tak">tak</mat-option>
                  <mat-option value="nie">nie</mat-option>
                </mat-select>
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Dodatkowe informacje/uwagi</mat-label>
                <textarea
                  formControlName="additionalData"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  matInput
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="_.get('child').value == false">
            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap="10px"
            >
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Imię</mat-label>
                <input formControlName="adultFirstName" type="text" matInput />
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Nazwisko</mat-label>
                <input formControlName="adultLastName" type="text" matInput />
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
            </div>

            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap="10px"
            >
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>Telefon</mat-label>
                <input formControlName="adultPhone" type="tel" matInput />
                <mat-error>Pole wymagane!</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex="48" appearance="outline">
                <mat-label>E-mail</mat-label>
                <input formControlName="adultEmail" type="email" matInput />

                <mat-error *ngIf="_.get('adultEmail').hasError('email')"
                  >E-mail nieprawidłowy!</mat-error
                >
                <mat-error *ngIf="_.get('adultEmail').hasError('required')"
                  >Pole wymagane!</mat-error
                >
              </mat-form-field>
            </div>
            <div
              fxLayout="row wrap"
              fxLayoutAlign="center stretch"
              fxLayout.xs="column"
              fxLayoutGap="10px"
            >
              <mat-form-field fxFlex="90" appearance="outline">
                <mat-label>Dodatkowe informacje/uwagi</mat-label>
                <textarea
                  formControlName="adultAdditionalData"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  matInput
                ></textarea>
              </mat-form-field>
            </div>
            <div class="ml-4">
              <!--              <div class="mat-body">-->
              <!--                Prosimy jeszcze o rozwiązanie krótkiego testu, żebyśmy mogli-->
              <!--                wstępnie ocenić Państwa poziom-->
              <!--              </div>-->
              <!--              <hr />-->
              <!--              <mat-checkbox (change)="fromStart = !fromStart">-->
              <!--                <span *ngIf="!fromStart">-->
              <!--                  Chcę zacząć naukę od początku-->
              <!--                </span>-->
              <!--                <span *ngIf="fromStart">-->
              <!--                  Jednak chcę wypełnić test-->
              <!--                </span>-->
              <!--              </mat-checkbox>-->
              <!--              &lt;!&ndash; <mat-checkbox (change)="fromStart = !fromStart" *ngIf="fromStart" >Jednak chcę rozwiązać test</mat-checkbox> &ndash;&gt;-->
              <!--              <app-adults-test [i]="i" (answersToSubmit)="updateAnswwers($event)" *ngIf="!fromStart"></app-adults-test>-->
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
        <button
          fxFlex="45"
          mat-raised-button
          type="button"
          color="primary"
          (click)="addStudent(students.controls.length)"
        >
          Dodaj Słuchacza
        </button>
        <button
          *ngIf="students.controls.length > 1"
          fxFlex="45"
          mat-raised-button
          type="button"
          color="warn"
          (click)="removeStudent(i)"
        >
          Usuń Słuchacza {{ _.value?.adultFirstName }}
          {{ _.value?.adultLastName }}{{ _.value?.childFirstName }}
          {{ _.value?.childLastName }}
        </button>
      </div>
    </div>
  </div>

  <div class="ml-4 mt-3">
    <!--    <h4>-->
    <!--      <b> Liczba miejsc w grupie jest ograniczona.</b>-->
    <!--      <br />-->
    <!--      W celu potwierdzenia rezrewacji miejsca w grupie prosimy o-->
    <!--      <b>wpłatę zadatku 50 zł</b> (będzie on odliczony od ceny kursu).-->
    <!--      <br />-->
    <!--      Dane do przelewu:-->
    <!--      <br />-->
    <!--      <br />-->
    <!--      Ling King Agnieszka Jacek <br />-->
    <!--      Barycka 37E <br />-->
    <!--      86-005 Białe Błota <br />-->
    <!--      <b> Nr. konta : 61 1090 1896 0000 0001 3510 9965 </b> <br />-->
    <!--      <b>Tytuł przelewu: zadatek na poczet kursu językowego za słuchacza (imię i-->
    <!--        nazwisko Słuchacza)</b>-->
    <!--    </h4>-->

    <mat-checkbox
      *ngIf="!internal"
      [style.color]="
        formSubmitted && signUpData.controls.termsAccepted.errors
          ? 'red'
          : 'black'
      "
      formControlName="termsAccepted"
      color="primary"
      ><span id="termsAcceptText">
        Oświadczam, że przeczytałem / przeczytałam i akceptuję warunki
        <a href="https://lingking.pl/polityka-prywatnosci/"
          >polityki prywatności*</a
        >
      </span>
    </mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-confirmation *ngIf="confirmation"></app-confirmation>
  </div>
  <div fxLayout="row " fxLayoutAlign="stretch stretch">
    <button
      *ngIf="internal"
      (click)="
        dialogRef.close(); $event.preventDefault(); $event.stopPropagation()
      "
      class="m-3"
      mat-stroked-button
      color="warn"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="!formSubmitted || !signUpData.valid" class="ml-2"
          >cancel</mat-icon
        >
        anuluj
      </div>
    </button>
    <div fxFlex></div>
    <button
      [disabled]="confirmation"
      type="submit"
      fxFlex
      class="m-3"
      mat-raised-button
      color="primary"
      *ngIf="!shortestVersion"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        Wyślij<mat-icon *ngIf="!formSubmitted || !signUpData.valid" class="ml-2"
          >send</mat-icon
        ><mat-spinner
          class="ml-2"
          *ngIf="formSubmitted && signUpData.valid"
          color="accent"
          diameter="20"
        ></mat-spinner>
      </div>
    </button>
  </div>
</form>
<!-- </div> -->
