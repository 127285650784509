import {
  ChangeDetectorRef,
  Component,
  Injectable,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { WeekViewHourSegment } from 'calendar-utils';

import { fromEvent, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { colors } from '../schedule/calendar-colors';
import { CustomDateFormatter } from './custom-date-formatter-provider';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleAddGroupComponent } from './schedule-add-group/schedule-add-group.component';
import { WorkersAvatarsService } from '../../services/workers-avatars.service';
import { HelpersService } from '../../services/helpers.service';
import { SmallDataService } from '../../services/small-data.service';
import { SignupFormService } from '../../services/signup-form.service';
import { TopBarVisibilityService } from 'src/app/profile/top-bar-visibility.service';
function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {}
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  styles: [
    `
      .disable-hover {
        pointer-events: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleComponent implements OnInit {
  selectedTeacher;
  editMode = false;
  teachers = [];
  daysInWeek = 3;
  activeTeachers = [];
  dragToCreateActive = false;
  filterTeachersState;
  filteredMode = false;
  viewDate = new Date();
  events = [];
  singleSelectedDay = null;
  // exclude weekends
  excludeDays: number[] = [0, 6];
  users = [];
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  allEvents: any = null;
  tempGroupsSub: any;
  foundstudentsByIdSub: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private workersService: WorkersAvatarsService,
    private helpers: HelpersService,
    private smallDataService: SmallDataService,
    private signupService: SignupFormService,
    private topBarVisibilityService: TopBarVisibilityService,
  ) {}

  startDragToCreate(
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement,
  ) {
    if (this.editMode) {
      if (this.selectedTeacher) {
        const dragToSelectEvent: CalendarEvent = {
          id: this.events.length,
          title: '',
          start: segment.date,

          end: addMinutes(segment.date, 45),
          color: {
            primary: this.selectedTeacher.colorDark,
            secondary: this.selectedTeacher.colorLight,
          },
          draggable: true,
          resizable: {
            beforeStart: true, // this allows you to configure the sides the event is resizable from
            afterEnd: true,
          },

          meta: {
            teacher: this.selectedTeacher,
          },
        };
        this.events = [...this.events, dragToSelectEvent];
        this.allEvents = [...this.allEvents, dragToSelectEvent];
        const segmentPosition = segmentElement.getBoundingClientRect();
        this.dragToCreateActive = true;
        delete dragToSelectEvent.meta.tmpEvent;
        this.dragToCreateActive = false;
        this.smallDataService.saveNewTempGroup(dragToSelectEvent);
        this.refresh();
      } else {
        alert('Wybierz lektora!');
      }
    }
  }
  filterGroupsWithStudents(text) {
    if (text !== '') {
      this.filteredMode = true;
      if (!this.allEvents) {
        this.allEvents = this.helpers.clone(this.events);
      }
      this.events = this.events.filter((sevent) =>
        sevent.meta.students.some((student) =>
          (student.childFirstName + ' ' + student.childLastName)
            .toLowerCase()
            .includes(text.toLowerCase()),
        ),
      );
    } else {
      this.events = this.allEvents;
    }
  }
  setVisibleDays(event) {
    if (event.value.length > 0) {
      let allDays = [1, 2, 3, 4, 5];
      this.excludeDays = [0, 6];
      const numbers = event.value.map((number) => {
        return parseInt(number);
      });
      event.value.forEach((number) => {
        allDays = allDays.filter((day) => number !== day);
      });
      this.excludeDays = this.excludeDays.concat(allDays);
    } else {
      this.excludeDays = [0, 6];
    }
  }
  filterEventsForSelectedTeachers(bevent) {
    this.filteredMode = true;
    this.filterTeachersState = bevent;
    if (!this.allEvents) {
      this.allEvents = this.helpers.clone(this.events);
    }
    this.events = this.allEvents.filter((event) =>
      bevent.value.includes(event.meta.teacher.teacherId),
    );
  }
  showAllEvents() {
    this.filteredMode = false;
    this.events = this.allEvents;
  }
  refresh() {
    this.activeTeachers = this.teachers.filter((teacher) =>
      this.allEvents.some(
        (event) => event.meta.teacher.teacherId === teacher.teacherId,
      ),
    );

    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.viewDate = new Date(2022, 8, 6);
    this.tempGroupsSub = this.smallDataService
      .tempGeoupsUpdatedListener()
      .subscribe((tempGroups: any) => {
        tempGroups.forEach((sevent) => {
          sevent.start = new Date(sevent.start);
          sevent.end = new Date(sevent.end);
          sevent.draggable = false;
          sevent.resizable = { beforeStart: false, afterEnd: false };
        });

        this.events = tempGroups;
        this.allEvents = tempGroups;
        this.activeTeachers = this.teachers.filter((teacher) =>
          this.allEvents.some(
            (event) => event.meta.teacher.teacherId === teacher.teacherId,
          ),
        );
        tempGroups.forEach((group) => {
          this.signupService.getStudentsById(
            group.meta.studentsIds ? group.meta.studentsIds : [],
            group.dbId,
          );
          if (group.meta.location == 'Bydgoszcz') {
            group.color.primary = '#795548';
          }
          if (group.meta.location == 'bialeblota') {
            group.color.primary = '#757575';
          }
        });
        if (this.filteredMode) {
          this.filterEventsForSelectedTeachers(this.filterTeachersState);
        }
        if (this.editMode) {
          this.setEditMode(false);
        }
        this.topBarVisibilityService.toggle(true);
      });
    this.smallDataService.getTempGroups();
    this.teachers = this.workersService.workersWithAvatars.filter(
      (worker) => worker.teacherId,
    );
    this.foundstudentsByIdSub = this.signupService
      .foundstudentsByIdListener()
      .subscribe((foundStudentsForGroup: any) => {
        let groupToUpdate = this.allEvents.find(
          (event) => event.dbId === foundStudentsForGroup.dbId,
        );
        groupToUpdate.meta.students = foundStudentsForGroup.students;
        let groupToUpdate2 = this.events.find(
          (event) => event.dbId === foundStudentsForGroup.dbId,
        );
        groupToUpdate2.meta.students = foundStudentsForGroup.students;
      });
  }

  refresh2 = new Subject<void>();
  setEditMode(edit: boolean) {
    if (edit) {
      this.editMode = false;
      this.events.forEach((sevent) => {
        sevent.draggable = false;
        sevent.resizable = { beforeStart: false, afterEnd: false };
      });
    }
    if (!edit) {
      this.editMode = true;
      this.events.forEach((sevent) => {
        sevent.draggable = true;
        sevent.resizable = { beforeStart: true, afterEnd: true };
      });
    }
  }
  editGroup(group) {
    const dialogRef = this.dialog.open(ScheduleAddGroupComponent, {
      disableClose: true,
      width: '80vw',
      height: '80vh',
      data: { groupToEdit: group },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let eventToReplace = this.events.find(
          (event) => event.id === result.id,
        );
        eventToReplace = result;
        let eventToReplaceFromAllEvents = this.allEvents.find(
          (event) => event.id === result.id,
        );
        this.smallDataService.updateTempGroup(result);
        eventToReplaceFromAllEvents = result;
        this.refresh2.next();
      }
    });
    // todo update this in backend
  }
  eventClicked({ event }: { event: CalendarEvent }): void {
    this.editGroup(event);
  }
  eventTimesChanged({ event, newStart, newEnd }) {
    if (this.editMode) {
      const previousStart = event.start;
      const previousEnd = event.end;
      event.start = newStart;
      event.end = newEnd;
      const works = this.smallDataService.checkAvalibility(
        event.meta.students,
        newStart,
        newEnd,
      );
      if (works) {
        this.smallDataService.updateTempGroup(event);
      } else {
        if (confirm('Czy zapisać mimo to?')) {
          this.smallDataService.updateTempGroup(event);
        } else {
          event.start = previousStart;
          event.end = previousEnd;
        }
      }

      //check students avalibility here
      this.refresh2.next();
    }
  }
  ngOnDestroy(): void {
    this.foundstudentsByIdSub.unsubscribe();
  }
}
